import React, { Component } from 'react';
import { connect } from "react-redux";
import API from '../utils/api';
import ListGroup from 'react-bootstrap/ListGroup';
import Loader from '../utils/loader';
import AsyncSelect from 'react-select/async';
import _ from "lodash";
import { FaMapMarker } from "react-icons/fa";
import {
  setLocation,
  clearLocation
} from '../redux/actions/locationActions';

import {
  fetchListPlaces,
  fetchMoreListPlaces,
  fetchAllPlacesAlpha
} from '../redux/actions/placeActions';
import FuzzySet from 'fuzzyset'
let a = new FuzzySet();
class AllPlacesAlpha extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placesloading:true,
      page:0,
      loadingmore:false,
      searchVisible:false,
      searchtext:'',
      geoplaces:[],
      filter:'all'
    }
  }

  componentDidMount() {
    this.setState({
      placesloading:true
    })
    this.props.fetchAllPlacesAlpha();
  }
  loadSet = () => {

    this.props.places.listplaces.map(place=>{
      a.add(place.name);
    })
  }
  showSet = () => {
    console.log(a.values())
    var dups = this.props.places.listplaces.map(place=>{
      var score = a.get(place.name,[],.75)
      if (score.length > 1) {
        return score
      } else {
        return null
      }
    })
    this.setState({
      dupsloaded : true,
      dups:dups
    })
    console.log('the dups',dups.map(dup=>{
      if(dup) {
        console.log(dup)

      }
    }))
  }

  render() {
    return this.props.places.listplacesloading ? (
      <div className="container">
      loading
      </div>
    ) : (
      <div className="container">
      {this.state.dupsloaded &&
        <div>
        {this.state.dups.map(dup=>{
          if(dup) {
            return (
              <div style={{backgroundColor:'lightgrey',margin:5}}>
                {dup.map((thing,index)=>{
                  return (
                    <div>{dup[index][0]} - {dup[index][1]}</div>
                  )
                })}
              </div>
            )
          } else {
            return
          }
        })}
        </div>
      }

      <button
        onClick={()=>{this.loadSet()}}
      >load set</button>
      <button
        onClick={()=>{this.showSet()}}
        >show set</button>
        <div className="userList" style={{marginTop:15}}>
        <ListGroup>
          {this.props.places.listplaces.map((place,index)=>{
            return (
              <>
              <ListGroup.Item key={place._id} className="userRow"
                style={{
                  cursor:'pointer',
                  display:'flex',
                  flexDirection:'row'
                }}
                onClick={()=>{
                this.props.history.push('/place/'+place._id);
              }}>
              <div style={{
                flex:1
              }}>
              {index + 1} - {place.name}
              </div>
              <div style={{
                width:20,
                height:20,
                borderRadius:10,
                backgroundColor:place.internalReviewStatus === "new" ? "red" : place.internalReviewStatus === "needsreview" ? "yellow" : place.internalReviewStatus === "completedGood" ? "green" : "blue"
              }}></div>
              {place.status == 'disabled' &&
              <div>disabled</div>
            }
              </ListGroup.Item>
              </>
            )
          })}
          </ListGroup>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  geoLocation:state.location,
  places:state.mapplaces
});

export default connect(mapStateToProps,{setLocation,clearLocation,fetchListPlaces,fetchMoreListPlaces,fetchAllPlacesAlpha})(AllPlacesAlpha)
