import axios from "axios";
import {
  FETCH_MAP_PLACES_STARTED,
  FETCH_MAP_PLACES_SUCCESS,
  FETCH_MAP_PLACES_ERROR,
  FETCH_LIST_PLACES_STARTED,
  FETCH_LIST_PLACES_ERROR,
  FETCH_LIST_PLACES_SUCCESS,
  FETCH_MORE_LIST_PLACES_STARTED,
  FETCH_MORE_LIST_PLACES_ERROR,
  FETCH_MORE_LIST_PLACES_SUCCESS

} from "./types";
import {baseUrl} from '../../config.js';
import API from '../../utils/api';
import store from '../store';
// Register User


//fetch FETCH_MAP_PLACES_START
export const fetchMapPlaces = () => dispatch => {
  dispatch(fetchMapPlacesStarted());
  API.fetch({
    method:'POST',
    endpoint:'/super/maplist/',
  })
  .then(res=>{
    dispatch(fetchMapPlacesSuccess(res.data))
  })
  .catch(err=>{
    dispatch(fetchMapPlacesError(err))
  })
}
const fetchMapPlacesStarted = () => ({
  type:FETCH_MAP_PLACES_STARTED
})
const fetchMapPlacesSuccess = places => ({
  type:FETCH_MAP_PLACES_SUCCESS,
  payload: {
    places
  }
})
const fetchMapPlacesError = error => ({
  type:FETCH_MAP_PLACES_ERROR,
  payload: {
    error
  }
})




export const fetchListPlaces = (params) => dispatch => {
  console.log('seaching in location',params.location)
  dispatch(fetchListPlacesStarted());
  API.fetch({
    method:'POST',
    endpoint:'/super/placelist/',
    data:{
      page: 0,
      location:params.location,
      filter:params.filter
    }
  })
  .then(res=>{
    dispatch(fetchListPlacesSuccess(res.data))
  })
  .catch(err=>{
    dispatch(fetchListPlacesError(err))
  })
}
const fetchListPlacesStarted = () => ({
  type:FETCH_LIST_PLACES_STARTED
})
const fetchListPlacesSuccess = places => ({
  type:FETCH_LIST_PLACES_SUCCESS,
  payload: {
    places
  }
})
const fetchListPlacesError = error => ({
  type:FETCH_LIST_PLACES_ERROR,
  payload: {
    error
  }
})


export const fetchAllPlacesAlpha = () => dispatch => {
  dispatch(fetchListPlacesStarted());
  API.fetch({
    method:'POST',
    endpoint:'/super/maplist/',
  })
  .then(res=>{
    dispatch(fetchListPlacesSuccess(res.data))
  })
  .catch(err=>{
    dispatch(fetchListPlacesError(err))
  })
}
export const fetchMoreListPlaces = (params) => dispatch => {
  console.log('fetching more list places!',params.location)
  dispatch(fetchMoreListPlacesStarted());
  API.fetch({
    method:'POST',
    endpoint:'/super/placelist/',
    data:{
      page: store.getState().mapplaces.listplacespage + 1,
      location:params.location,
      filter:params.filter,
    }
  })
  .then(res=>{
    dispatch(fetchMoreListPlacesSuccess(res.data))
  })
  .catch(err=>{
    dispatch(fetchMoreListPlacesError(err))
  })
}
const fetchMoreListPlacesStarted = () => ({
  type:FETCH_MORE_LIST_PLACES_STARTED
})
const fetchMoreListPlacesSuccess = places => ({
  type:FETCH_MORE_LIST_PLACES_SUCCESS,
  payload: {
    places:places,
    newpage:store.getState().mapplaces.listplacespage + 1
  }
})
const fetchMoreListPlacesError = error => ({
  type:FETCH_MORE_LIST_PLACES_ERROR,
  payload: {
    error
  }
})
