import React, { Component, useRef } from 'react';
import { connect } from "react-redux";
import Loader from '../utils/loader';
import API from '../utils/api';
import fetchs3image from '../utils/fetchs3';
import autosize from 'autosize';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { WithContext as ReactTags } from 'react-tag-input';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import {mapboxToken} from '../config.js';
import axios from 'axios';
import {BsXCircleFill} from 'react-icons/bs';
import { saveAs } from 'file-saver';
import {QRCodeCanvas} from 'qrcode.react';
import canvasToImage from 'canvas-to-image';
import QRCodeStyling from "qr-code-styling";
import BizCode from './bizcode';

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const Map = ReactMapboxGl({
  accessToken: mapboxToken,
});

const qrCode = new QRCodeStyling({
  width: 300,
  height: 300,
  dotsOptions: {
    color: "#4267b2",
    type: "rounded"
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 20
  }
});

const downloadQR = (placeName) => {
  const canvas = document.getElementById("myqrCode");
  const pngUrl = canvas
    .toDataURL("image/png")
    .replace("image/png", "image/octet-stream");
  let downloadLink = document.createElement("a");
  downloadLink.href = pngUrl;
  downloadLink.download = placeName + "QR.png";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

class BusinessProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      place:null,
      loading:true,
      tagline:'',
      updating:false,
      features:[]
    }
    this.textAreaRef = React.createRef();
    this._tagHandleDelete = this._tagHandleDelete.bind(this);
    this._tagHandleAddition = this._tagHandleAddition.bind(this);
    this.updateDragLocation = this.updateDragLocation.bind(this);
    this.qrRef = React.createRef();
    this.meref = React.createRef();
  }

  componentDidMount() {
    qrCode.append(this.meref.current)
    const scrollHeight = this.textAreaRef.current?.scrollHeight;
    console.log('THE SCROLL HEIGHT',scrollHeight)
    console.log(this.textAreaRef);
    API.fetch({
      method:'POST',
      endpoint:'/super/place/',
      data: {
        placeId:this.props.match.params.id
      }
    })
    .then(response=>{
      console.log('the place response',response)
      this.setState({
        place:response.data[0],
        loading:false
      })
      autosize(this.textarea);
    })
    .catch(error=>{
      console.log('error',error)
      this.setState({error:true,loading:true})
    })
  }
  async updateDragLocation(coords) {
    try {
      const locationName = await axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + coords[0]+','+coords[1]+'.json', {
        params: {
          access_token : process.env.REACT_APP_MAPBOX_TOKEN,
          cachebuster : '1578867295345',
          autocomplete: false,
          types:'place'
        }
      })
      console.log('the location name is', locationName)
      this.setState({
        place: {...this.state.place,location : {...this.state.place.location, coordinates:[coords[0],coords[1]],name:locationName.data.features[0].place_name}}
      })

    } catch(error) {
      console.log('error updating place', error);
      alert('error!')
    }
    //get new place name.
  }

  image_upload() {
    this.setState({
      imageUploading:true
    })
    const file = document.getElementById('locationImage').files[0]
    //file: document.getElementById('locationImage').files[0]
    API.image({
      endpoint:'places/',
      file:file

    })
    .then((result)=>{
        this.setState({
          place: {
            ...this.state.place, image : {
              ...this.state.place.image, s3image:result
            }
          },
          imageUploading:false
          })
      })
      .catch((error)=>{
        this.setState({
          imageUploading:false
        })
      })
    }
    delete = () => {
      let doit = window.confirm(`are you sure you want to delete ${this.state.place.name}? this is permanent!`);
      if(doit) {
        var confirm = window.prompt(`please type the place name: ${this.state.place.name} to delete`);
        if(confirm === this.state.place.name) {
          API.fetch({
            method:'POST',
            endpoint:'/super/place/delete',
            data: {
              placeId:this.state.place._id
            }
          }).then(res=>{
            alert('deleted!')
            this.props.history.push('/placelist');
          }).catch(error=>{
            alert('API error deleting')
          })
        } else {
          alert('incorrect name entered')
        }
      } else {
        console.log('cancelled the delte')
      }
    }
    update = (e) => {
      e.preventDefault();
      if(this.state.updating) {
        return;
      }
      this.setState({
        updating:true
      })
      API.fetch({
        method:'POST',
        endpoint:'/super/place/update/' + this.state.place._id,
        data: {
          place:this.state.place
        }
      })
      .then(res=>{
        //this.props.updateBusiness(this.state.place);
        this.setState({
          updating:false
        })
      })
      .catch(error=>{
        this.setState({
          updating:false
        })
      })
    }
    _tagHandleDelete(i) {
        const { tags } = this.state.place;
        this.setState({
          place: {...this.state.place,tags: tags.filter((tag, index) => index !== i)}
        });
    }

    _tagHandleAddition(tag) {
      this.setState(state=>({
        place:{...state.place,tags: [...state.place.tags, tag]}
      }))
    }
    search = (search) => {
      var searchtext = encodeURIComponent(search) + '.json';
      axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + searchtext, {
        params: {
          access_token : process.env.REACT_APP_MAPBOX_TOKEN,
          cachebuster : '1578867295345',
          autocomplete: false,
          country:'us',
          types:'address,poi,neighborhood,locality,postcode,district,place'
        }
      }).then(res=>{
        console.log(res)
        this.setState({
          features:res.data.features
        })
      }).catch(error=>{
        console.log('error!',error)
      })
    }
    setNewPoints = (feature) => {
      console.log('featyre',feature);
      let city,state,postcode;
      feature.context.forEach((v,i) => {
        if (v.id.indexOf("postcode") >= 0) {
          postcode = v.text;
        }
        if (v.id.indexOf("place") >= 0) {
          city = v.text;
        }
        if (v.id.indexOf("region") >= 0) {
          state = v.text;
        }


      })
      this.setState({
        place: {...this.state.place, location: {...this.state.place.location,city:city,postcode:postcode,state:state}}
      })
      console.log('new c/s/p', city,state,postcode)
      this.updateDragLocation(feature.geometry.coordinates)
      this.setState({
        features:[],
        search:''
      })
    }
    manualupdate = () => {
      console.log('running manual ')
      if(!this.state.newlat || !this.state.newlong) {
        alert('need to set lat/lng');
        return;
      }
      console.log('setting')
      this.setState({
        place: {...this.state.place,location : {...this.state.place.location, coordinates:[this.state.newlong,this.state.newlat],name:""}}
      })

    }
  render() {
    console.log(this.state)
    return this.state.loading ? (
      <div className="loadScreen"><Loader/></div>
    ) : this.state.error ? (
      <div>error</div>
    ) : this.state.updating ? (
      <div className="loadScreen"><Loader/></div>
    ) : (
      <div className="mainContainer top-buffer" style={{marginBottom:75}}>
      <Container fluid>
        <Row>
          <Col xl={5}>
          <div
            style={{
              paddingTop:'65%',
              background:`url(${fetchs3image({bucket:this.state.place.image?.s3image?.bucket,key:this.state.place.image?.s3image?.key})}) center / cover`,
              backgroundSize:'cover',
              backgroundPosition:'center'
            }}
            className="profilePicHolder"
          >
          {this.state.imageUploading ? (
              <div className="profilePicUploadingTrue">
              <Loader/>
              </div>
          ) : (
            <div className="profilePicUploadButton">
            <input
              className="dougInput"
              id="locationImage"
              type="file"
              accept="image/*"
              className={this.state.location ? "" : "form-control"}
              style={{border:0,boxShadow:"0"}}
              onChange={(e)=>{this.image_upload()}}
              />
              </div>

          )}
          <div style={{bottom:5,right:5,position:'absolute'}}>
            <button className="btn btn-danger btn-block"
            onClick={()=>{
              saveAs(fetchs3image(this.state.place.image?.s3image), this.state.place.name.replace(/\s/g, '') ) // Put your image url here.
            }}
            >Download Image</button>
          </div>
          </div>
          <div className="businessProfileMap">
              <Map
                style="mapbox://styles/mapbox/streets-v9"
                containerStyle={{
                  height:'500px',
                  width: '100%'
                }}
                zoom={[15]}
                center={[this.state.place.location.coordinates[0],this.state.place.location.coordinates[1]]}
              >
                <Layer type="symbol" id="marker" layout={{ 'icon-image': 'circle-15' }}>
                  <Feature
                    coordinates={[this.state.place.location.coordinates[0],this.state.place.location.coordinates[1]]}
                    draggable={true}
                    onDragStart={(e)=>{console.log(e);}}
                    onDragEnd={(e)=>{
                      console.log(e.lnglat)
                      //var templocation = this.state.location.coordinates;
                      var templocation = []
                      templocation[0] = e.lngLat.lng;
                      templocation[1] = e.lngLat.lat;
                      console.log('HERE',templocation);
                      this.updateDragLocation(templocation);
                      //
                      // this.setState({
                      //   templocation
                      // })
                    }}
                  />
                </Layer>
              </Map>
              <div className="businessProfileSearchBar">
                <div className="bpbarwrap"
                  style={{position:'relative'}}
                >
                  <Form.Control type="text" placeholder="search for an address"
                  onChange={(e)=>{
                    this.setState({
                      search:e.target.value
                    })
                    this.text = e.target.value;
                    clearTimeout(this.timeout);
                    this.timeout = setTimeout(()=>this.search(this.text),500)
                  }}
                  value={this.state.search}
                  />
                  <BsXCircleFill style={{
                    position: 'absolute',
                    right: 7,
                    top: 11,
                    cursor:'pointer',
                    color:'rgb(94,94,94)'
                  }}
                  onClick={()=>{
                    this.setState({
                      search:'',
                      features:[]
                    })
                  }}
                  />
                </div>
              <div className="businessProfileSearchResultsWrap">
                {this.state.features.map(feat=>{
                  return (
                    <div
                    className="businessProfileSearchResultItem"
                    onClick={()=>{this.setNewPoints(feat)}}
                    >{feat.place_name}</div>
                  )
                })}
              </div>

              </div>
              <div style={{
                display:'flex',
                flexDirection:'row'
              }}>
              <input
              style={{flex:1}}
              value={this.state.newlat}
              onChange={(e)=>{
                this.setState({
                  newlat:e.target.value
                })
              }}
              placeholder="latitude"
              />
              <input
              style={{flex:1}}
              value={this.state.newlong}
              onChange={(e)=>{
                this.setState({
                  newlong:e.target.value
                })
              }}
              placeholder="longitutde"
              />
              <button
                onClick={()=>{
                  if(!this.state.newlat || !this.state.newlong) {
                    alert('need a lat/lng');
                    return;
                  }
                  var templocation = []
                  templocation[0] = this.state.newlong
                  templocation[1] = this.state.newlat;
                  console.log('HERE',templocation);
                  this.updateDragLocation(templocation);
                }}
              >update</button>
              </div>
          </div>
          <div>
            <BizCode value={this.state.place._id} name={this.state.place.name}/>
            <div>
            </div>
          </div>
          </Col>

          <Col xl={7}>
          <div style={styles.placearrows}>
            <div style={styles.placecounter}>
            {this.props.places.listplaces.findIndex(e=>e._id === this.props.match.params.id) + 1} / {this.props.places.listplaces.length}
            </div>
            <div style={styles.placearrow}
            onClick={()=>{
              var index = this.props.places.listplaces.findIndex(e=>e._id === this.props.match.params.id);
              console.log(index)
              var prev = this.props.places.listplaces[index - 1];
              console.log(this.props.history)
              this.props.history.replace('/place/'+prev._id);
              this.setState({
                loading:true
              })
              API.fetch({
                method:'POST',
                endpoint:'/super/place/',
                data: {
                  placeId:prev._id
                }
              })
              .then(response=>{
                console.log('the place response',response)
                this.setState({
                  place:response.data[0],
                  loading:false
                })
                autosize(this.textarea);
              })
              .catch(error=>{
                console.log('error',error)
                this.setState({error:true,loading:true})
              })
            }}
            >
            prev
            </div>
            <div style={styles.placearrow}
            onClick={()=>{
              var index = this.props.places.listplaces.findIndex(e=>e._id === this.props.match.params.id);
              console.log(index)
              var next = this.props.places.listplaces[index + 1];
              console.log(this.props.history)
              this.props.history.replace('/place/'+next._id);
              this.setState({
                loading:true
              })
              API.fetch({
                method:'POST',
                endpoint:'/super/place/',
                data: {
                  placeId:next._id
                }
              })
              .then(response=>{
                console.log('the place response',response)
                this.setState({
                  place:response.data[0],
                  loading:false
                })
                autosize(this.textarea);
              })
              .catch(error=>{
                console.log('error',error)
                this.setState({error:true,loading:true})
              })
            }}
            >
            next
            </div>

          </div>
          {this.state.place.userDoc.length > 0 &&
            <div style={{
              display:'flex',
              fledDirection:'row',
              alignItems:'center',
              background:'#dddddd',
              padding:5,
              margin:5
            }}>
            <img src={this.state.place.userDoc[0].tinyBase64ProfileImage} style={{marginRight:4}}/>
            <div>Place added by {this.state.place.userDoc[0].userName}</div>
            <div
            style={styles.awardPointsBox}
            onClick={()=>{
              API.fetch({
                method:'POST',
                endpoint:'/super/points/awardforplaceadded',
                data: {
                  placeId:this.props.match.params.id,
                  userId:this.state.place.userDoc[0]._id
                }
              }).then(res=>{
                console.log('success',res)
              }).catch(error=>{
                console.log('error doing opnts reward',error.response.data.message)
                alert('Error: ' + error.response.data.message)
              })

            }}
            >award points to {this.state.place.userDoc[0].userName}</div>
            </div>
          }
            <Form onSubmit={this.update}>
              <Form.Group className="businessProfileFormGroupMargin">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="tagline"
                onChange={(e)=>{
                  this.setState({
                    place:{...this.state.place,name:e.target.value}
                  })
                }}
                value={this.state.place.name}
                />
                <Form.Text className="text-muted">Business / Place name.</Form.Text>
              </Form.Group>
              <Form.Group className="businessProfileFormGroupMargin">
                <Form.Label>Tagline</Form.Label>
                <Form.Control type="text" placeholder="tagline"
                onChange={(e)=>{
                  this.setState({
                    place:{...this.state.place,tagline:e.target.value}
                  })
                }}
                value={this.state.place.tagline}
                />
                <Form.Text className="text-muted">A quick way to describe your business.</Form.Text>
              </Form.Group>
              <Form.Group className="businessProfileFormGroupMargin">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" placeholder="description"
                rows={20}
                ref={this.textAreaRef}
                onChange={(e)=>{
                  this.setState({
                    place:{...this.state.place,description:e.target.value}
                  })
                }}
                value={this.state.place.description}
                />
                <Form.Text className="text-muted">The main description of your business.</Form.Text>
              </Form.Group>
              <Form.Group className="businessProfileFormGroupMargin">
                <Form.Label>Phone</Form.Label>
                <PhoneInput
                style={{width:'auto'}}
                  placeholder="Enter phone number"
                  value={this.state.place.phone}
                  onChange={(e)=>{
                    this.setState({
                      place : {
                        ...this.state.place,phone:e
                      }
                    })
                  }}
                />
                <Form.Text>For U.S. numbers, please enter a 1 at the start of the number</Form.Text>
              </Form.Group>
              <Form.Group className="businessProfileFormGroupMargin">
                <Form.Label>Tags</Form.Label>
                <ReactTags tags={this.state.place.tags}
                    suggestions={this.state.suggestions}
                    handleDelete={this._tagHandleDelete}
                    handleAddition={this._tagHandleAddition}
                    delimiters={delimiters}
                    allowDragDrop={false}
                     />
                <Form.Text>Add tags to have your business show up in searches.</Form.Text>
              </Form.Group>
              <Form.Group className="md-3" controlId="stickerState">
                <Form.Label style={{
                  backgroundColor:this.state.place.internalReviewStatus === "new" ? "red" : this.state.place.internalReviewStatus === "needsreview" ? "yellow" : this.state.place.internalReviewStatus === "completedGood" ? "green" : "blue",
                  color:this.state.place.internalReviewStatus === "completedRejected" ? "white" : "black",
                  padding:6,
                  borderRadius:3
                }}>Review Status</Form.Label>
                <Form.Select
                  value={this.state.place.internalReviewStatus}
                  onChange={(e)=>{
                    this.setState({
                      place: {...this.state.place,internalReviewStatus:e.target.value}
                    })
                  }}
                  >
                  <option value="new">new</option>
                  <option value="needsreview">needs review</option>
                  <option value="completedGood">completed - good</option>
                  <option value="completedRejected">completed - rejected</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="md-3" controlId="stickerState">
                <Form.Label style={{
                  backgroundColor:this.state.place.internalReviewStatus === "new" ? "red" : this.state.place.internalReviewStatus === "needsreview" ? "yellow" : this.state.place.internalReviewStatus === "completedGood" ? "green" : "blue",
                  color:this.state.place.internalReviewStatus === "completedRejected" ? "white" : "black",
                  padding:6,
                  borderRadius:3
                }}>Place Active Status</Form.Label>
                <Form.Select
                  value={this.state.place.status}
                  onChange={(e)=>{
                    this.setState({
                      place: {...this.state.place,status:e.target.value}
                    })
                  }}
                  >
                  <option value="active">active</option>
                  <option value="disabled">disabled</option>
                  <option value="deleted">deleted</option>
                </Form.Select>
              </Form.Group>

              <Button variant="dougBlue" className="btn-danger" type="submit" style={{marginTop:20}}>submit</Button>
            </Form>
            <Button onClick={this.delete} variant="dougRed" className="btn-info" type="submit" style={{marginTop:20}}>delete {this.state.place.name}</Button>
          </Col>
        </Row>
      </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  business:state.business,
  places:state.mapplaces
});

export default connect(mapStateToProps,{})(BusinessProfile)

const styles = {
  placearrows: {
    display:'flex',
    flexDirection:'row'
  },
  placearrow : {
    width:65,
    height:55,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    background:'#ebe0d0',
    margin:5,
    cursor:'pointer',
    borderRadius:5
  },
  placecounter : {
    fontSize:8,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    width:55,
    height:55
  },
  awardPointsBox : {
    fontSize:9,
    background:'lightgrey',
    marginLeft:10,
    padding:12,
    cursor:'pointer'
  }
}
