import {
  SET_CURRENT_USER,
  USER_LOADING,
  USER_NOT_LOADING,
  UPDATE_TOKENS,
  REFRESH_CURRENT_USER
} from "../actions/types";
import jwt_decode from "jwt-decode";

const isEmpty = require("is-empty");

const initialState = {
  isAuthenticated: false,
  user: {},
  loading: false,
  access_token:null,
  refresh_token:null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload.decoded,
        access_token:action.payload.access_token,
        refresh_token:action.payload.refresh_token
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true
      };
    case USER_NOT_LOADING:
      return {
        ...state,
        loading:false
      }
    case REFRESH_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        access_token:action.payload.access_token,
        refresh_token:action.payload.refresh_token,
        user:{
          ...state.user,exp:jwt_decode(action.payload.access_token).exp
        }
      }

    default:
      return state;
  }
}
