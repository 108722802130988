

const Fetchs3image = ({bucket,key,width}) => {
  var url = 'https://d2pzmg9707fubo.cloudfront.net/';
  var obj = {
    "bucket": bucket,
    "key": key,
    "edits": {
      "resize": {
        "width": width ? width : 1200,
        "fit": "cover"
      }
    }
  }
  var encoded = btoa(JSON.stringify(obj));
  var imageUrl = url + encoded;
  return imageUrl;
}

export default Fetchs3image;
