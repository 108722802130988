import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import {baseUrl} from '../../config.js';
import jwt_decode from "jwt-decode";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING,
  USER_NOT_LOADING
} from "./types";
// Register User
export const registerUser = (userData, history) => dispatch => {
  axios
    .post("/api/auth/register", userData)
    .then(res => history.push("/login")) // re-direct to login on successful register
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};
// Login - get user token
export const loginUser = userData => dispatch => {
  return new Promise(function(resolve,reject){
  var decoded = jwt_decode(userData.access_token)
  if(decoded.authLevel.includes('administrator')) {
    localStorage.setItem("dougsuperlogin",JSON.stringify({decoded:decoded,access_token:userData.access_token,refresh_token:userData.refresh_token}))
    console.log('we set the token to',localStorage.getItem("dougsuperlogin"))
    dispatch(setCurrentUser({decoded:decoded,access_token:userData.access_token,refresh_token:userData.refresh_token}));
    resolve(decoded);

  } else {
    reject('not authorized')
  }
  })
};
// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};
// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};
export const setUserNotLoading = () => {
  return {
    type:USER_NOT_LOADING
  }
}
// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("dougsuperlogin");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
