import React, { Component } from 'react';
import { connect } from "react-redux";
import API from '../utils/api';
import ListGroup from 'react-bootstrap/ListGroup';
import Loader from '../utils/loader';
import SingleVibeView from './singleVibeView';
class VibeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vibesloading:true,
      page:0,
      loadingmore:false
    }
  }
  componentDidMount() {
    this.setState({
      usersloading:true
    })
    API.fetch({
      method:'POST',
      endpoint:'/super/vibelist',
      data:{page:this.state.page}
    }).then(res=>{
      this.setState({
        vibes:res.data,
        vibesloading:false
      })
      console.log(res.data)
    }).catch(error=>{
      console.log(error)
      this.setState({
        vibesloading:false,
        error:true,
        users:[]
      })
    })
  }
  loadmore() {
    var newpage = this.state.page + 1;
    this.setState({
      page:newpage,
      loadingmore:true
    })
    API.fetch({
      method:'POST',
      endpoint:'/super/vibelist',
      data:{page:newpage}
    }).then(res=>{
      this.setState({
        vibes:[...this.state.vibes,...res.data],
        loadingmore:false
      })
      console.log('page',newpage)
      console.log('new vibes loaded',res.data)
    }).catch(error=>{
      console.log(error)
      this.setState({
        loadingmore:false,
        error:true,
        users:[]
      })
    })
  }
  render() {
    console.log(this.state)
    return this.state.vibesloading ? (
      <div className="container">
      loading vibes
      </div>
    ) : (
      <div className="container">
      <div className="vibeViewWrapper">
      <div className="vibeListWrapper">
        {this.state.vibes.map(vibe=>{
          return (
            <SingleVibeView vibe={vibe} history={this.props.history}/>
          )
        })}
      </div>
      {this.state.loadingmore ? (
          <Loader/>
      ) : (

        <button onClick={
          ()=>{
            this.loadmore()
          }
        }>load more</button>
      )}
      </div>

      </div>

    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  geoLocation:state.location
});

export default connect(mapStateToProps,{})(VibeList)
