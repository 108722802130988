import React, { useEffect, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";
import heart from '../assets/doug_heart.png';
import scan from '../assets/scan3.png';
import Resizer from "react-image-file-resizer";
import './biz.css';

var fileDownload = require('react-file-download');

const qrCode = new QRCodeStyling({
  width: 2000,
  height: 2000,
  qrOptions: {
    typeNumber:10
  },
  dotsOptions: {
    color: "#303030",
    type: "rounded"
  },
  image:scan,
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 3,
    imageSize:.5
  }
});

export default function BizCode(props) {
  const [url, setUrl] = useState("https://qr-code-styling.com");
  const [fileExt, setFileExt] = useState("png");
  const ref = useRef(null);

  useEffect(() => {
    qrCode.append(ref.current);
    console.log('BIZ PROPS!!!!',props)
  }, []);

  useEffect(() => {
    qrCode.update({
      data: "https://douglovesyou.com/places/" + props.value
    });
  }, [url]);

  const onUrlChange = (event) => {
    event.preventDefault();
    setUrl(event.target.value);
  };

  const onExtensionChange = (event) => {
    setFileExt(event.target.value);
  };

  const onDownloadClick = () => {
    qrCode.download({
      extension: fileExt
    });
  };
  const onBlobClick = () => {
    qrCode.getRawData().then(raw=>{
        console.log('GOT THE RAW',raw)
        var qrurl = URL.createObjectURL(raw);
        console.log(qrurl)
        Resizer.imageFileResizer(
            raw,
            250,
            250,
            "PNG",
            100,
            0,
            (uri) => {
                console.log('the resized is',uri)
                fileDownload(uri, props.name.trim().replace(/\s+/g, '') + '.png')
            },
            "blob"
        )
    })
  }

  return (
    <div className="App">
      <div ref={ref} id="hello" className="qrcont"/>
      <div style={styles.inputWrapper}>
        <button onClick={onBlobClick}>Download QR Code</button>
      </div>

    </div>
  );
}

const styles = {
  inputWrapper: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  inputBox: {
    flexGrow: 1,
    marginRight: 20
  }
};
