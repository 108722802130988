import React, { Component } from 'react';
import { connect } from "react-redux";
import API from '../utils/api';
import ListGroup from 'react-bootstrap/ListGroup';
import Loader from '../utils/loader';
import AsyncSelect from 'react-select/async';
import _ from "lodash";
import { FaMapMarker } from "react-icons/fa";
import fetchs3image from '../utils/fetchs3';
import ReactMapboxGl, { Layer, Feature, Marker, Cluster, Popup } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {mapboxToken} from '../config.js';
import heart from '../assets/heart.png';
import ImageLoad from './ImageLoad';
import {fetchMapPlaces} from '../redux/actions/placeActions';
import {
  setLocation,
  clearLocation,
  setDistance
} from '../redux/actions/locationActions';

const Map = ReactMapboxGl({
  accessToken:
    mapboxToken
});


class PlaceGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placesloading:true,
      page:0,
      loadingmore:false,
      searchVisible:false,
      searchtext:'',
      geoplaces:[],
      filter:'all',
      showPopup:false,
      popupLatitude:null,
      popupLongitude:null,
      popupName:'',
      popupDescription:'',
      popupImage:null

    }
    this.loadOptionsbounce = _.debounce(this.loadOptions, 350);
    this.onMarkerClick = this.onMarkerClick.bind(this)
  }
  componentDidMount() {
    console.log('comp mount coords',this.props.geoLocation.coordinates)
    this.setState({
      placesloading:true
    })
    API.fetch({
      method:'POST',
      endpoint:'/super/gridlist/',
      data:{
        location:this.props.geoLocation.active ? this.props.geoLocation.coordinates : null,
        filter:this.state.filter,
        distance:this.props.geoLocation.distance
      }
    }).then(res=>{
      this.setState({
        places:res.data,
        placesloading:false
      })
      console.log(res.data)
    }).catch(error=>{
      console.log(error)
      this.setState({
        placesloading:false,
        error:true,
        users:[]
      })
    })
  }
  filterRefresh(filter) {
    this.setState({
      placesloading:true,
      page:0,
      searchText:''
    })
    API.fetch({
      method:'POST',
      endpoint:'/super/gridlist/',
      data:{
        location:this.props.geoLocation.active ? this.props.geoLocation.coordinates : null,
        filter:filter,
        distance:this.props.geoLocation.distance
      }
    }).then(res=>{
      this.setState({
        places:res.data,
        placesloading:false
      })
      console.log(res.data)
    }).catch(error=>{
      console.log(error)
      this.setState({
        placesloading:false,
        error:true,
        users:[]
      })
    })
  }
  loadmore() {
    var newpage = this.state.page + 1;
    this.setState({
      page:newpage,
      loadingmore:true
    })
    API.fetch({
      method:'POST',
      endpoint:'/super/gridlist',
      data:{page:newpage,location:this.props.geoLocation.active ? this.props.geoLocation.coordinates : null,filter:this.state.filter,distance:this.props.geoLocation.distance}
    }).then(res=>{
      this.setState({
        places:[...this.state.places,...res.data],
        loadingmore:false
      })
    }).catch(error=>{
      console.log(error)
      this.setState({
        loadingmore:false,
        error:true,
        users:[]
      })
    })
  }
  loadOptions = (inputValue) => {
    console.log('LOADING NEW STUFF **********')
    console.log('the input value',inputValue)
    //return fetch(`http://jsonplaceholder.typicode.com/posts?userId=${inputValue}`).then(res => res.json());
      return fetch('https://api.douglovesyou.com/places/unpagedsearch',{
        method:'POST',
        headers : {
          Authorization: "Bearer " + this.props.auth.user.signInUserSession.accessToken.jwtToken,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({search:inputValue})
      }).then(res=>res.json());
      // return API.fetch({
      //   method:'POST',
      //   endpoint:'/places/unpagedsearch',
      //   data:{
      //     search:inputValue
      //   }
      // }).then(res=>res.json());
  };
  handleChange = (value) => {
    this.setState({
      selectedValue : value
    })
    this.props.history.push('/place/'+value._id)
  }
  placeSearch = (text) => {
    API.fetch({
      method:'POST',
      endpoint:'/geo/searchforlocation',
      data: {
        search:text
      }
    })
    .then(result=>{
      console.log('search regult is ',result)
      this.setState({
        geoplaces:result.data.features
      })
    })
    .catch(error=>{
    })
  }
  reload = (coords) => {
    console.log('reloading these coords',coords)
    this.setState({
      placesloading:true,
      page:0,
      loadingmore:false,
      searchVisible:false,
      searchtext:'',
      geoplaces:[]

    })
    API.fetch({
      method:'POST',
      endpoint:'/super/gridlist/',
      data:{
        location:coords,
        filter:'all',
        distance:this.props.geoLocation.distance
      }
    }).then(res=>{
      console.log('result from the reload is',res.data)
      this.setState({
        places:res.data,
        placesloading:false
      })
      console.log(res.data)
    }).catch(error=>{
      console.log(error)
      this.setState({
        placesloading:false,
        error:true,
        users:[]
      })
    })
  }
  clusterMarker = (coordinates,pointCount) => (
    <Marker coordinates={coordinates} style={styles.clusterMarker}>
      <div style={styles.clusterContainer}>
      <img src={heart} style={styles.bigHeart}/>
      <div style={styles.pointCount}>{pointCount}</div>
      </div>
    </Marker>
  );
  onMarkerClick(e,other) {
    this.setState({
      popupLatitude:e.location.coordinates[1],
      popupLongitude:e.location.coordinates[0],
      popupName:e.name,
      popupTagline:e.tagline,
      popupImage:e.image.s3image,
      popupID:e._id,
      showPopup:true
    })


  }
  onMapClick(map,ev) {
  }

  render() {
    console.log(this.state)
    const zoom = [12];
    const center = [
      this.props.geoLocation.coordinates[0],
      this.props.geoLocation.coordinates[1]
    ]
    console.log('THE CENTER!!!!',center)

    return this.state.placesloading ? (
      <div className="container">
      loading
      </div>
    ) : (
      <div className="container">
        <div className="selectWrapPlaces"
          style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center'
          }}
        >
        <div style={{
          flex:1
        }}>
        <AsyncSelect
          loadOptions={this.loadOptionsbounce}
          value={this.state.selectedValue}
          onChange={this.handleChange}
          getOptionLabel={(e) => e.name}
          getOptionValue={(e) => e._id}
          placeholder={'Search places'}
          style={{
            flex:1,
            color:'red',
            width:'100%'
          }}
        />
        </div>
        <div style={{
          paddingLeft:15,
          paddingRight:15,
          position:'relative'
        }}
        >
        {this.props.geoLocation.active ? (
          <div
          onClick={()=>{
            this.setState({
                searchVisible:!this.state.searchVisible
            })
          }}
          style={{cursor:'pointer'}}
          >
          Searching in {this.props.geoLocation.name}<FaMapMarker style={{marginLeft:3}}/>
          </div>


      ) : (
        <div
        onClick={()=>{
          this.setState({
              searchVisible:!this.state.searchVisible
          })
        }}
        style={{cursor:'pointer'}}
        >
        Set location <FaMapMarker style={{marginLeft:3}}/>
        </div>


      )}

        {this.state.searchVisible &&
          <div
            style={{
              position:'absolute',
              top:30,
              right:10,
              width:250,
              border:'1px solid #dedede',
              borderRadius:5,
              zIndex:999,
              padding:6
            }}
          >
            <input
            style={{
              width:'100%'
            }}
            placeholder="type to teleport"
            value={this.state.searchtext}
            onChange={(e)=>{
              this.setState({
                searchtext:e.target.value
              })
              this.text = e.target.value;
              clearTimeout(this.timeout);
              this.timeout = setTimeout(()=>this.placeSearch(this.text),500);
            }}
            />
            <div style={{
              display:'flex',
              flexDirection:'row',
              marginTop:2,
              marginBottom:2
            }}>
            <input style={{
              width:'100%',
            }}
            type="range"
            min="1"
            max="50"
            value={this.props.geoLocation.distance}
            onChange={(e)=>{this.props.setDistance(e.target.value)}}
            />
            <div
            style={{
              cursor:'pointer',
              background:'red',
              width:34,
              height:34,
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              color:'white',
            }}
            onClick={()=>{this.reload(this.props.geoLocation.coordinates)}}
            >{this.props.geoLocation.distance}</div>
            </div>
            {this.state.geoplaces.length > 0 &&
              <div>
              {this.state.geoplaces.map(place=>{
                console.log('THE PLACE IS',place)
                return (
                  <div key={place.id}
                    style={{
                      background:'#efefef',
                      padding:4,
                      marginTop:4,
                      fontSize:11,
                      borderRadius:3,
                      cursor:'pointer'
                    }}
                    onClick={()=>{
                      console.log(place.geometry.coordinates)
                      this.props.setLocation({name:place.place_name,coords:place.geometry.coordinates})
                      this.setState({
                        geoplaces:[],
                        searchVisible:false,
                        searchtext:''
                      })
                      this.reload(place.geometry.coordinates)
                    }}
                  >{place.place_name}</div>
                )
              })}
              </div>
            }
            {this.props.geoLocation.active &&
            <div
            style={{
              background:'#efefef',
              padding:4,
              marginTop:4,
              fontSize:11,
              borderRadius:3,
              cursor:'pointer'
            }}
            onClick={()=>{
              this.props.clearLocation()
              this.setState({
                geoplaces:[],
                searchVisible:false,
                searchtext:''
              })
              this.reload();

            }}
            >Clear Location</div>
          }
        </div>
        }
      </div>
      </div>
      <div className="filterTool">
      <select
        value={this.state.filter}
        onChange={(e)=>{
          this.setState({
            filter:e.target.value
          })
          this.filterRefresh(e.target.value)
        }}
        style={{
          padding:8,
          marginTop:5
        }}
      >
          <option value="all">all</option>
          <option value="new">new</option>
          <option value="needsreview">needs review</option>
          <option value="completedGood">completed - good</option>
          <option value="completedRejected">completed - rejected</option>
      </select>
      </div>
      {this.props.mapplaces.mapplacesloading ? (
        <div
        style={{
          height: 'calc(60vh - 110px)',
          width: '100%',
          backgroundColor:'#f2f2f2',
          display:'flex',
          justifyContent:'center',
          alignItems:'center'
        }}
        ><div style={{cursor:'pointer'}} onClick={()=>{this.props.fetchMapPlaces()}}>fetch map places</div></div>
      ) : (
      <div style={{
        position:'relative'
      }}>
      <Map
        style="mapbox://styles/mapbox/streets-v9"
        containerStyle={{
          height: 'calc(60vh - 110px)',
          width: '100%'
        }}
        zoom={zoom}
        center={center}
        onClick={(e)=>{
          this.setState({
            showPopup:false
          })
        }}
        onDragEnd={(e)=>{
          console.log('drag end',e.transform.center.lat)
          this.props.setLocation({name:'dragging...',coords:[e.transform.center.lng,e.transform.center.lat]})
          this.reload([e.transform.center.lng,e.transform.center.lat])
        }}
      >
      <Cluster ClusterMarkerFactory={this.clusterMarker}>
      {this.props.mapplaces.mapplaces.places.map((place,key)=>{
        return (
          <Marker
            key={key}
            coordinates={[place.location.coordinates[0], place.location.coordinates[1]]}
            anchor="bottom"
            onClick={this.onMarkerClick.bind(this, place)}>
            <img src={heart} style={styles.heart}/>
          </Marker>

        )

      })}
      </Cluster>
      <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
        <Feature coordinates={[this.props.geoLocation.coordinates[0], this.props.geoLocation.coordinates[1]]} />
      </Layer>
      {this.state.showPopup &&
        <Popup
          coordinates={[this.state.popupLongitude,this.state.popupLatitude]}
          offset={{
            'bottom-left': [12, -38],  'bottom': [0, -38], 'bottom-right': [-12, -38]
          }}>
          <div style={styles.popupWrap}>
          <div style={styles.popupName}>{this.state.popupName}</div>
          <div style={styles.popupTagline}>{this.state.popupTagline}</div>
          <div style={styles.popupImage}>
            {this.state.popupImage ? (
              <ImageLoad src={this.state.popupImage}/>
            ) : (null)
          }
          </div>
          <div style={styles.editplace} onClick={()=>this.props.history.push('/place/'+ this.state.popupID)}>edit</div>

          </div>
        </Popup>
      }
      </Map>
      <div style={{
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%,-50%)'

      }}>x</div>
      </div>
    )}
        <div className="gridList" style={{marginTop:15}}>
          {this.state.places.map(place=>{
            return (
              <div key={place._id} className="gridListItemWrap"
                onClick={()=>{
                this.props.history.push('/place/'+place._id);
              }}>
                <div className="gridListItemPicture"
                style={{
                  background:`url(${fetchs3image({bucket:place.image?.s3image?.bucket,key:place.image?.s3image?.key,width:400})}) center / cover`,
                }}>
                </div>
                <div className="gridListBottom">
                <div className="gridListTextWrap">
                  <div className="gridListTextName">
                  {place.name}
                  </div>
                  <div className="gridListTextTagline">
                  {place.tagline}
                  </div>
                </div>
                  {place.tags.length > 0 ? (
                    <div className="gridListTagCloud">
                      {place.tags.map(tag=>{
                        return (
                          <div className="gridListTag">{tag.text}</div>
                        )
                      })}
                    </div>

                  ) : (
                    <div className="gridListTagCloud">
                      <div className="gridListTag" style={{backgroundColor:'red',color:'white'}}>no tags</div>
                      </div>
                  )}
                </div>

              </div>
            )
          })}
        </div>
        {this.state.loadingmore ? (
            <Loader/>
        ) : (

          <button onClick={
            ()=>{
              this.loadmore()
            }
          }
          style={{
            marginTop:10,
            marginBottom:75
          }}
          >load more</button>
        )}
        <div>{this.state.places.length} places loaded...</div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  geoLocation:state.location,
  mapplaces:state.mapplaces
});

export default connect(mapStateToProps,{setLocation,clearLocation,setDistance,fetchMapPlaces})(PlaceGrid)


const styles = {
  heart : {
    width:20,
    height:20
  },
  bigHeart : {
    width:40,
    height:40
  },
  pointCount : {
    position:'absolute',
    top:0,
    left:0,
    bottom:0,
    right:0,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    color:'white'
  },
  popupWrap : {
    width:200
  },
  popupName: {
    fontSize:15,
    marginBottom:2,
    lineHeight:1
  },
  popupTagline: {
    fontSize:10,
    lineHeight:1.2,
    marginBottom:5
  },
  popupImage: {
    width:190,

  },
  editplace : {
    color:'red',
    fontSize:9,
    cursor:'pointer'
  }
}
