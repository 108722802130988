import React, { Component } from "react";
import { connect } from "react-redux";
import ListGroup from 'react-bootstrap/ListGroup';
import {Table} from 'react-bootstrap';
import Loader from '../../utils/loader';
import API from '../../utils/api';
import fetchs3image from '../../utils/fetchs3';
import autosize from 'autosize';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';


class CharityList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      charities:[]
    }
  }
  componentDidMount() {
    API.fetch({
      method:'GET',
      endpoint:'/super/charity/list'
    }).then(res=>{
      console.log('charities',res.data)
      this.setState({
        charities:res.data,
        loading:false
      })
    }).catch(error=>{
      console.log(error)
    })
  }
  render() {
    return this.state.loading ? (
      <div>Charity List</div>
    ) : (
      <div className="container">
        <div className="userList" style={{marginTop:15}}>
        <Table striped bordered hover>
          <thead>
          <th>Charity Title</th>
          <th>Charity Description</th>
          <th>Charity Place</th>
          </thead>
          <tbody>
          {this.state.charities.map(charity=>{
            return (
              <tr key={charity._id}
                onClick={()=>{
                  this.props.history.push('/charity/edit/'+charity._id);
                }}
              >
              <td>{charity.name}</td>
              <td>{charity.description}</td>
              <td>tbd</td>
              </tr>
            )
          })}

          </tbody>
        </Table>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  auth: state.auth,
  business:state.business,
  places:state.mapplaces,
  geoLocation:state.location

});

export default connect(mapStateToProps,{})(CharityList)