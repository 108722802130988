import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Login from './components/Login';
import PrivateRoute from "./utils/privateRoute";
import LoginRoute from './utils/loginRoute';

import Home from './components/Home';
import UserList from './components/UserList';
import UserView from './components/UserView';
import NavHeader from './components/navHeader';
import VibeList from './components/VibeList';
import BusinessProfile from './components/businessProfile';
import PlaceList from './components/PlaceList';
import AddedPlaceList from './components/addedplacelist';
import PlacesDisabled from './components/PlacesDisabled.js';
import GridList from './components/PlaceGrid';
import FormsList from './components/FormsList';
import FormView from './components/FormView';
import EmailsList from './components/EmailsList';
import CreatePlace from './components/CreatePlace';
import CreateExperience from './components/CreateExperience';
import ExperiencesList from './components/ExperiencesList';
import AllPlacesAlpha from './components/AllPlacesAlpha';
import EditExperience from './components/editExperience';
import CreateRecommendationList from './components/createRecommendationList';
import EditRecommendationList from './components/editRecommendationList';
import RecommendationsListAll from './components/RecommendationListAll';
import AddReward from './components/rewards/addReward';
import RewardList from './components/rewards/rewardsList';
import EditReward from './components/rewards/editReward';
import CharityList from './components/charity/charityList';
import AddCharity from './components/charity/addCharity';
import EditCharity from './components/charity/editCharity';

import BigMap from './components/BigMap';
class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return (
      <>
      <Router>
      <NavHeader/>
        <LoginRoute exact path="/login" component={Login}/>
        <Switch>
        <PrivateRoute exact path="/" exact component={Home} />
        <PrivateRoute exact path="/userlist" exact component={UserList}/>
        <PrivateRoute exact path="/user/:id" exact component={UserView}/>
        <PrivateRoute exact path="/vibelist" exact component={VibeList}/>
        <PrivateRoute exact path="/place/:id" exact component={BusinessProfile}/>
        <PrivateRoute exact path="/placelist" exact component={PlaceList} />
        <PrivateRoute exact path="/placesadded" exact component={AddedPlaceList} />
        <PrivateRoute exact path="/placesdisabled" exact component={PlacesDisabled} />
        <PrivateRoute exact path="/allplacesalpha" exact component={AllPlacesAlpha} />
        <PrivateRoute exact path="/gridlist" exact component={GridList} />
        <PrivateRoute exact path="/places/new" exact component={CreatePlace} />
        <PrivateRoute exact path="/experiences/new" exact component={CreateExperience} />
        <PrivateRoute exact path="/experiences/list" exact component={ExperiencesList} />
        <PrivateRoute exact path="/experience/:id" exact component={EditExperience}/>
        <PrivateRoute exact path="/recommendations/new" exact component={CreateRecommendationList} />
        <PrivateRoute exact path="/recommendations/list" exact component={RecommendationsListAll} />
        <PrivateRoute exact path="/recommendations/:id" exact component={EditRecommendationList}/>
        <PrivateRoute exact path="/formslist" exact component={FormsList} />
        <PrivateRoute exact path="/forms/:id" exact component={FormView}/>
        <PrivateRoute exact path="/emailslist" exact component={EmailsList} />
        <PrivateRoute exact path="/rewards/list" component={RewardList} />
        <PrivateRoute exact path="/rewards/new" component={AddReward} />
        <PrivateRoute exact path="/rewards/edit/:id" component={EditReward} />
        <PrivateRoute exact path="/charity/list" component={CharityList} />
        <PrivateRoute exact path="/charity/new" component={AddCharity} />
        <PrivateRoute exact path="/charity/edit/:id" component={EditCharity}/>
        <PrivateRoute exact path="/map" component={BigMap} />
        </Switch>
      </Router>
      </>

    )
  }
}

export default Navigation;
