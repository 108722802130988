import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from '../../utils/loader';
import API from '../../utils/api';
import AsyncSelect from 'react-select/async';
import {baseUrl} from '../../config.js';

class Backers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount () {
        this.getBackers();
        
    }
    addBacker = async(backer) => {
        console.log(backer)
        var backer = await API.fetch({
            method:'POST',
            endpoint:'/super/backer/new',
            data: {
                charity:this.props.charityid,
                business:backer,
                notes:'we added a backer'
            }
        })
        this.getBackers();
    }
    deleteBacker = async(backer) => {
        var delbacker = await API.fetch({
            method:'POST',
            endpoint:'/super/backer/delete',
            data: {
                id:backer
            }
        })
        this.getBackers();
    }
    getBackers = async() => {
        try {
            console.log('hello hello hello',this.props.charityid)
        var backers = await API.fetch({
            method:'GET',
            endpoint:'/super/backer/charityid/' + this.props.charityid
        
          })
          console.log('backers are',backers)
          this.setState({backers:backers.data})
        } catch(error) {
            console.log(error)
        }
    }
    loadOptions = (inputValue) => {
        console.log('LOADING NEW STUFF **********')
        console.log('the input value',inputValue)
        //return fetch(`http://jsonplaceholder.typicode.com/posts?userId=${inputValue}`).then(res => res.json());
          return fetch(baseUrl + '/places/unpagedsearch',{
            method:'POST',
            headers : {
              Authorization: "Bearer " + this.props.auth.access_token,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body:JSON.stringify({search:inputValue})
          }).then(res=>res.json())
          // return API.fetch({
          //   method:'POST',
          //   endpoint:'/places/unpagedsearch',
          //   data:{
          //     search:inputValue
          //   }
          // }).then(res=>res.json());
      };
      handleChange = (value) => {
        console.log('the selected value',value)
        this.addBacker(value._id)
      }
    render() {
        console.log('backers state',this.state)
        console.log('backers props', this.props)
        return (
            <div>
            <div>backers</div>
        <AsyncSelect
          loadOptions={this.loadOptions}
          value={this.state.selectedValue}
          onChange={this.handleChange}
          getOptionLabel={(e) => e.name}
          getOptionValue={(e) => e._id}
          placeholder={'Search places'}
          style={{
            flex:1,
            color:'red',
            width:'100%'
          }}
        />
            {this.state.backers?.length > 0 ? (
                <>
                    {this.state.backers.map(back=>{
                        return (
                            <div style={{display:'flex',flexDirection:'row'}}>
                                <div>{back.place.name}</div>
                                <div onClick={()=>{this.deleteBacker(back._id)}}>delete</div>    
                            </div>
                        )
                    })}
                </>
            ) : (
                <div>no backers</div>
            )}

            </div>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
 
  });
  
  export default connect(mapStateToProps,{})(Backers)
  

