import React, { Component } from 'react';
import { connect } from "react-redux";
import API from '../utils/api';
import ListGroup from 'react-bootstrap/ListGroup';
import Lottie from 'lottie-react';
import moment from 'moment';
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersloading:true,
      vibesloading:true
    }
  }
  componentDidMount() {

    API.fetch({
      method:'GET',
      endpoint:'/super/last10users'
    }).then(res=>{
      this.setState({
        users:res.data,
        usersloading:false
      })
    }).catch(error=>{
      console.log('error',error)
    })

    API.fetch({
      method:'GET',
      endpoint:'/super/last10vibes'
    }).then(res=>{
      console.log('the last 10 vibes',res.data)
      this.setState({
        vibesloading:false,
        vibes:res.data
      })
    }).catch(error=>{
      console.log('error',error)
    })
  }

  render() {
    return (
      <div className="container">
      <div style={styles.homelistcontainer}>
      <h4>10 newest users...</h4>
      {this.state.usersloading ? (
          <div>users loading</div>
      ) : (
        <div className="userList">
        <ListGroup>
          {this.state.users.map(user=>{
            return (
              <ListGroup.Item key={user._id} className="userRow"
                style={{
                  cursor:'pointer',
                  display:'flex',
                  flexDirection:'row',
                  alignItems:'center'
                }}
                onClick={()=>{
                this.props.history.push('/user/'+user._id);
              }}>
              <div style={{
                flex:1
              }}>
              <img src={user.tinyBase64ProfileImage} style={{borderRadius:25,width:50,height:50,marginRight:10}}/>{user.userName}
              </div>
              <div style={{width:'10%',textAlign:'right'}}>{moment(user.createdAt).fromNow()}</div>
              </ListGroup.Item>
            )
          })}
          </ListGroup>
        </div>

      )}
      </div>
      <div style={styles.homelistcontainer}>
      <h4>10 latest vibes...</h4>
      {this.state.vibesloading ? (
          <div>vibes loading</div>
      ) : (
        <div className="userList">
        <ListGroup>
          {this.state.vibes.map(vibe=>{
            return (
              <ListGroup.Item key={vibe._id} className="userRow"
                style={{
                  cursor:'pointer',
                  display:'flex',
                  flexDirection:'row',
                  alignItems:'center'
                }}
                onClick={()=>{
              }}>
              <div style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
                flex:1
              }}>
              {vibe.vibeDoc[0]?.lottie &&
                <Lottie
                  animationData={JSON.parse(vibe.vibeDoc[0]?.lottie)}
                  style={{
                    width:40,
                    height:40,
                    marginRight:8
                  }}
                  autoplay
                  loop
                />

              }
              {vibe.vibeDoc[0]?.vibeTitle}
              </div>
              <div style={{
                display:'flex',
                flexDirection:'column',
                alignItems:'flex-end'
              }}>
              <div onClick={
                ()=>this.props.history.push('/place/' + vibe.placeDoc[0]._id)
              }>{vibe.userDoc[0].userName} @ {vibe.placeDoc[0].name}</div>
              <div style={{fontSize:10}}>{vibe.location.name}</div>
              </div>
              <div style={{width:'10%',textAlign:'right'}}>{moment(vibe.createdAt).fromNow()}</div>
              </ListGroup.Item>
            )
          })}
          </ListGroup>
        </div>

      )}
      </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  geoLocation:state.location
});

export default connect(mapStateToProps,{})(Home)

const styles = {
  centerme : {
    height:'calc(100vh - 60px)',
    width:'100%',
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  homelistcontainer : {
    padding:25,
    backgroundColor:'#efefef',
    borderRadius:10,
    marginTop:15
  }
}
