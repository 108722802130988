import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Loader from './loader';
const LoginRoute = ({ component: Component, auth, ...rest }) => {

  return (
    <Route
      {...rest}
      render={props =>
        auth.loading ? <div style={styles.centerme} className="container loadScreen"><Loader/></div> :
        auth.isAuthenticated === true ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}
LoginRoute.propTypes = {
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(LoginRoute);

const styles = {
  centerme: {
    width:'100%',
    height:'calc(100vh - 60px)',
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  }
}
