import React, { Component } from "react";
import { connect } from "react-redux";
import ListGroup from 'react-bootstrap/ListGroup';
import {Table} from 'react-bootstrap';
import Loader from '../../utils/loader';
import API from '../../utils/api';
import fetchs3image from '../../utils/fetchs3';
import autosize from 'autosize';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';


class RewardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      rewards:[]
    }
  }
  componentDidMount() {
    API.fetch({
      method:'GET',
      endpoint:'/rewards/list'
    }).then(res=>{
      console.log('rewqards',res.data)
      this.setState({
        rewards:res.data,
        loading:false
      })
    }).catch(error=>{
      console.log(error)
    })
  }
  render() {
    return this.state.loading ? (
      <div>Reward List</div>
    ) : (
      <div className="container">
        <div className="userList" style={{marginTop:15}}>
        <Table striped bordered hover>
          <thead>
          <th>Reward Title</th>
          <th>Reward Description</th>
          <th>Reward Place</th>
          </thead>
          <tbody>
          {this.state.rewards.map(reward=>{
            return (
              <tr key={reward._id}
                onClick={()=>{
                  this.props.history.push('/rewards/edit/'+reward._id);
                }}
              >
              <td>{reward.rewardTitle}</td>
              <td>{reward.rewardDescription}</td>
              <td>{reward.placeDoc[0].name}</td>
              </tr>
            )
          })}

          </tbody>
        </Table>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  auth: state.auth,
  business:state.business,
  places:state.mapplaces,
  geoLocation:state.location

});

export default connect(mapStateToProps,{})(RewardList)