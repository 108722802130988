import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from '../../utils/loader';
import API from '../../utils/api';
import fetchs3image from '../../utils/fetchs3';
import autosize from 'autosize';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import {baseUrl} from '../../config.js';
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

class EditReward extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId:"604967642019ed6621b86350",
      place:null,
      rewardTitle:'',
      rewardDescription:'',
      usePerUser:1,
      startDate:new Date(),
      endDate:new Date(),
      imageUploading:false,
      name:'',
      tagline:'',
      rewardType:'percent',
      description:'',
      flash:false,
      image: {
        s3image : null
      },
      location: {
        type:'Point',
        coordinates:this.props.geoLocation.coordinates,
        name:this.props.geoLocation.name
      },
      loading:true,
    }
  }
  componentDidMount() {
    console.log('reward id is',this.props.match.params.id)
    API.fetch({
      method:'GET',
      endpoint:'/super/rewards/list/' + this.props.match.params.id
    }).then(res=>{
      console.log('this reward is',res.data)
      const {reward,placeDoc} = res.data;
      console.log('PLACE',placeDoc)
      this.setState({
        place:reward.place,
        rewardTitle:reward.rewardTitle,
        rewardDescription:reward.rewardDescription,
        usePerUser:reward.usePerUser,
        startDate:new Date(reward.startDate),
        endDate:new Date(reward.endDate),
        rewardType:reward.rewardType,
        rewardAmount:reward.rewardAmount,
        flash:reward.flash,
        rewardImage:reward.rewardImage,
        placeDoc:placeDoc,
        loading:false,
        rewardId:reward._id
      })
    }).catch(error=>{
      console.log('error fetching reward')
    })
  }
  image_upload() {
    this.setState({
      imageUploading:true
    })
    const file = document.getElementById('locationImage').files[0]
    //file: document.getElementById('locationImage').files[0]
    API.image({
      endpoint:'places/',
      file:file

    })
    .then((result)=>{
      console.log('the reward image',result)
        this.setState({
          rewardImage: {
              ...this.state.rewardImage, s3image:result
            },
          imageUploading:false
          })
      })
      .catch((error)=>{
        this.setState({
          imageUploading:false
        })
      })
    }

    createplace = () => {
      if(
        !this.state.place ||
        !this.state.rewardTitle ||
        !this.state.rewardDescription ||
        !this.state.usePerUser ||
        !this.state.startDate ||
        !this.state.endDate ||
        !this.state.rewardType ||
        !this.state.rewardAmount
      ) {
        alert('please fill everything out')
        return;

      } else {

        const requestBody = {
          name: this.state.name,
          description: this.state.description,
          tagline: this.state.tagline,
          location:this.state.location,
          image:this.state.image
        }
        const config = {
          headers : {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
        API.fetch({
          method:'POST',
          endpoint:'/super/rewards/update',
          data:this.state
        }).then(res=>{
          console.log('reward created ',res)
          //this.props.history.push('/place/'+res.data._id)
        }).catch(error=>{
          console.log('error',error)
          alert('error')
        })

      }
    }
    deleteReward = () => {
      console.log('DELTEING THIS REWARD',this.state.rewardId)
      API.fetch({
        method:'POST',
        endpoint:'/super/rewards/delete',
        data:{rewardId:this.state.rewardId}
      }).then(res=>{
        console.log('reward delte',res.data)
      }).catch(error=>{
        console.log('error deleteing reward',error)
      })
    }
    loadOptions = (inputValue) => {
      console.log('LOADING NEW STUFF **********')
      console.log('the input value',inputValue)
      //return fetch(`http://jsonplaceholder.typicode.com/posts?userId=${inputValue}`).then(res => res.json());
        return fetch(baseUrl + '/places/unpagedsearch',{
          method:'POST',
          headers : {
            Authorization: "Bearer " + this.props.auth.access_token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body:JSON.stringify({search:inputValue})
        }).then(res=>res.json())
        // return API.fetch({
        //   method:'POST',
        //   endpoint:'/places/unpagedsearch',
        //   data:{
        //     search:inputValue
        //   }
        // }).then(res=>res.json());
    };
    handleChange = (value) => {
      this.setState({
        selectedValue : value
      })
      this.setState({
        place:value._id,
        location:value.location,
        placeDoc:value
      })
      //this.props.history.push('/place/'+value._id)
      console.log('the selected value',value)
    }
  render() {
    console.log(this.state)
    return this.state.loading ? (
      <div>loading</div>
    ) : (
      <Container>
        <Form style={{marginBottom:75}}>
        <Form.Group className="businessProfileFormGroupMargin">
          <Form.Label>Reward Place</Form.Label>
          <div>{this.state.placeDoc.name}</div>
        <AsyncSelect
          loadOptions={this.loadOptions}
          value={this.state.selectedValue}
          onChange={this.handleChange}
          getOptionLabel={(e) => e.name}
          getOptionValue={(e) => e._id}
          placeholder={'Search places'}
          style={{
            flex:1,
            color:'red',
            width:'100%'
          }}
        />
        </Form.Group>
        <Form.Group className="businessProfileFormGroupMargin">
          <Form.Label>Reward Title</Form.Label>
          <Form.Control type="text" placeholder="business name"
          onChange={(e)=>{
            this.setState({
              rewardTitle:e.target.value
            })
          }}
          value={this.state.rewardTitle}
          />
        </Form.Group>
        <Form.Group className="businessProfileFormGroupMargin">
          <Form.Label>Reward Description</Form.Label>
          <Form.Control as="textarea" placeholder="description"
          rows={5}
          ref={this.textAreaRef}
          onChange={(e)=>{
            this.setState({
              rewardDescription:e.target.value
            })
          }}
          value={this.state.rewardDescription}
          />
          <Form.Text className="text-muted">Describe exactly how the reward works</Form.Text>
        </Form.Group>
        <Form.Group className="businessProfileFormGroupMargin">
          <Form.Label>Use per user</Form.Label>
          <Form.Control type="text" placeholder="tagline"
          onChange={(e)=>{
            this.setState({
              usePerUser:e.target.value
            })
          }}
          value={this.state.usePerUser}
          />
          <Form.Text className="text-muted">How many times can a user use this reward?</Form.Text>
        </Form.Group>
        <Form.Group className="businessProfileFormGroupMargin">
          <Form.Label>Start date</Form.Label>
          <DatePicker
            selected={this.state.startDate}
            showTimeSelect
            dateFormat="Pp"
            onChange={(date:Date) => {
            this.setState({
              startDate:date
            })
          }} />
        </Form.Group>
        <Form.Group className="businessProfileFormGroupMargin">
          <Form.Label>End date</Form.Label>
          <DatePicker
            selected={this.state.endDate}
            showTimeSelect
            dateFormat="Pp"
            onChange={(date:Date) => {
            this.setState({
              endDate:date
            })
          }} />
        </Form.Group>

        <Form.Group className="businessProfileFormGroupMargin">
          <Form.Label>Reward Type</Form.Label>
          <br/>
          <select value={this.state.rewardType}
            onChange={(e)=>{
              this.setState({
                rewardType:e.target.value
              })
            }}
          >
            <option value="percent">percent</option>
            <option value="fixed">fixed amount</option>
            <option value="other">other</option>
          </select>
        </Form.Group>
        <Form.Group className="businessProfileFormGroupMargin">
          <Form.Label>Reward Amount</Form.Label>
          <Form.Control type="text" placeholder="tagline"
          onChange={(e)=>{
            this.setState({
              rewardAmount:e.target.value
            })
          }}
          value={this.state.rewardAmount}
          />
          <Form.Text className="text-muted">Value of this reward as either a percentage or dollar amount.</Form.Text>
        </Form.Group>
        <Form.Group className="businessProfileFormGroupMargin">
        <Form.Label>Flash?</Form.Label>
        <Form.Check type="checkbox"
        checked={this.state.flash}
        onChange={(e)=>{
          this.setState({
            flash:e.target.checked
          })
        }}
        />
        </Form.Group>
        {this.state.rewardImage?.s3image ? (
          <div
            style={{
              width:600,
              height:400,
              background:`url(${this.state.rewardImage?.s3image ? fetchs3image(this.state.rewardImage?.s3image) : null}) center / cover`,
              backgroundSize:'cover',
              backgroundPosition:'center'
            }}
            className="profilePicHolder"
          >
          {this.state.imageUploading ? (
              <div className="profilePicUploadingTrue">
              <Loader/>
              </div>
          ) : (
            <div className="profilePicUploadButton">
            <input
              className="dougInput"
              id="locationImage"
              type="file"
              accept="image/*"
              className={this.state.location ? "" : "form-control"}
              style={{border:0,boxShadow:"0"}}
              onChange={(e)=>{this.image_upload()}}
              />
              </div>

          )}

          </div>

        ) : (
          <div className="">
          <div>Reward Image</div>
          <input
            className="dougInput"
            id="locationImage"
            type="file"
            accept="image/*"
            className={this.state.location ? "" : "form-control"}
            style={{border:0,boxShadow:"0"}}
            onChange={(e)=>{this.image_upload()}}
            />
            </div>
        )
        }


        </Form>
        <Button variant="dougBlue" className="btn-danger"
        onClick={this.createplace}
        >submit</Button>
        <Button variant="red" className="btn-danger"
        onClick={this.deleteReward}
        >delete</Button>
      </Container>
    )
  }
}


const mapStateToProps = state => ({
  auth: state.auth,
  business:state.business,
  places:state.mapplaces,
  geoLocation:state.location

});

export default connect(mapStateToProps,{})(EditReward)
