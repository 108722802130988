import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import React, { Component } from 'react';
import { connect } from "react-redux";
import {logoutUser} from '../redux/actions/authActions';
import API from '../utils/api';

class PlaceFromId extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true
    }
  }
  componentDidMount() {
    API.fetch({
      method:'GET',
      endpoint:'/admin/places/' + this.props.placeId
    }).then(res=>{
      this.setState({
        place:res.data,
        loading:false
      })
    }).catch(error=>{
      console.log(error)
    })
  }
  render() {
    console.log(this.state)
    return this.state.loading ? (
      <div>*</div>
    ):(
      <div style={{
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
      }}>{this.props.placeId} - {this.state.place.name} - <div style={{marginLeft:5,borderRadius:5,color:'white',backgroundColor:'red',padding:6}} onClick={()=>{this.props.remove(this.props.placeId)}}>remove</div></div>
    )
  }
}




export default PlaceFromId;
