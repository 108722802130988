import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import React, { Component } from 'react';
import { connect } from "react-redux";
import {logoutUser} from '../redux/actions/authActions';
import {fetchMapPlaces} from '../redux/actions/placeActions';

import { withRouter } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap';


class NavHeader extends Component {
  render() {
    return (
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand onClick={()=>{this.props.history.push('/')}}>
            <img src={require("../assets/doug_heart.png")}
            width="80"
             />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="container-fluid">
              {this.props.auth.isAuthenticated ? (
                <>
                <Nav.Link onClick={()=>{this.props.history.push('/')}}>Home</Nav.Link>
                <Nav.Link onClick={()=>{this.props.history.push('/userlist')}}>User List</Nav.Link>
                <Nav.Link onClick={()=>{this.props.history.push('/vibelist')}}>Vibe List</Nav.Link>
                <NavDropdown title="Places" id="basic-nav-dropdown">
                <LinkContainer to="/placelist">
                  <NavDropdown.Item>List</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/gridlist">
                  <NavDropdown.Item>Grid List</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/map">
                  <NavDropdown.Item>Map View</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to="/places/new">
                  <NavDropdown.Item>New</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/allplacesalpha">
                  <NavDropdown.Item>All Places Alphabetical</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/placesdisabled">
                  <NavDropdown.Item>All Places Disabled</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/placesadded">
                  <NavDropdown.Item>Added Place List</NavDropdown.Item>
                </LinkContainer>

                </NavDropdown>
                <NavDropdown title="Experiences" id="basic-nav-dropdown">
                <LinkContainer to="/experiences/new">
                  <NavDropdown.Item>New</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/experiences/list">
                  <NavDropdown.Item>List</NavDropdown.Item>
                </LinkContainer>

                </NavDropdown>
                <NavDropdown title="RecommendationLists" id="basic-nav-dropdown">
                <LinkContainer to="/recommendations/new">
                  <NavDropdown.Item>New</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/recommendations/list">
                  <NavDropdown.Item>List</NavDropdown.Item>
                </LinkContainer>

                </NavDropdown>
                <NavDropdown title="Rewards" id="basic-nav-dropdown">
                <LinkContainer to="/rewards/new">
                  <NavDropdown.Item>New</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/rewards/list">
                  <NavDropdown.Item>List</NavDropdown.Item>
                </LinkContainer>

                </NavDropdown>
                <NavDropdown title="Charity" id="basic-nav-dropdown">
                <LinkContainer to="/charity/new">
                  <NavDropdown.Item>New</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/charity/list">
                  <NavDropdown.Item>List</NavDropdown.Item>
                </LinkContainer>

                </NavDropdown>

                <NavDropdown title="Forms / Email lists" id="basic-nav-dropdown2">
                <LinkContainer to="/formslist">
                  <NavDropdown.Item>Form Submissions</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/emailslist">
                  <NavDropdown.Item>Email Signups</NavDropdown.Item>
                </LinkContainer>

                </NavDropdown>
                <Nav.Link href="https://www.youtube.com/watch?v=PIHtUxnRN0c" target="_blank">How to Doug</Nav.Link>
                <Nav.Link onClick={()=>this.props.logoutUser()} className="ms-auto">hi Doug - log out
                </Nav.Link>
                </>
              ) : (
                  <>
                  </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )

  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps,{logoutUser,fetchMapPlaces})(withRouter(NavHeader))
