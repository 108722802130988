import React, { Component } from 'react';
import './App.css';
import { Provider } from "react-redux";
import store from "./redux/store.js";
import { loginUser } from "./redux/actions/authActions";
import Navigation from './Navigation';
import './experience.css';

class App extends Component {
  async componentWillMount() {
    try {
      const res = await localStorage.getItem("dougsuperlogin");
      if(res) {
        const session = await JSON.parse(res);
        console.log('reclaimed super login',session);
        console.log('we have a session')
        store.dispatch({
          type:'SET_CURRENT_USER',
          payload:session
        })
      } else {
        console.log('no session!')
      }
    } catch(error) {
      console.log('session refresh error',error)
    }
  }
  render() {
    return (
      <Provider store={store}>
      <Navigation/>
      </Provider>
    )
  }
}

export default App;
