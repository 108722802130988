import {
  SET_LOCATION,
  CLEAR_LOCATION,
  SET_DISTANCE
} from "../actions/types";

const isEmpty = require("is-empty");

const initialState = {
    active:false,
    name: 'Taos, New Mexico, United States',
    coordinates : [
      -105.598668,
      36.3892411
    ],
    distance:25
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_LOCATION:
      return {
        ...state,
        active:true,
        name:action.payload.name,
        coordinates:action.payload.coords
      }
    case CLEAR_LOCATION:
      return initialState;
    case SET_DISTANCE:
      return {
        ...state,
        distance:action.payload
      }

    default:
      return state;
  }
}
