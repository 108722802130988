import React, { Component, useRef } from 'react';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import Loader from '../utils/loader';
import { connect } from "react-redux";
import API from '../utils/api';
import fetchs3image from '../utils/fetchs3';

class CreateRecommendationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name:'',
      location: {
        type:'Point',
        coordinates:this.props.geoLocation.coordinates,
        name:this.props.geoLocation.name
      },
    }
  }

    createExperience = () => {
      if(
        !this.state.name
      ) {
        alert('please fill everything out')
        return;

      } else {

        const requestBody = {
          name: this.state.name,
          location:this.state.location,
        }
        const config = {
          headers : {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
        API.fetch({
          method:'POST',
          endpoint:'/super/recommendationlist/add',
          data:requestBody
        }).then(res=>{
          console.log('recommendationlist created ',res)
          //this.props.history.push('/experience/'+res.data._id)
        }).catch(error=>{
          console.log('error',error)
          alert('error')
        })

      }
    }
  render() {
    return (
      <Container>
        <Form style={{marginBottom:75}}>
        <Form.Group className="businessProfileFormGroupMargin">
          <Form.Label>Recommendation List Name</Form.Label>
          <Form.Control type="text" placeholder="Recommendation List name"
          onChange={(e)=>{
            this.setState({
              name:e.target.value
            })
          }}
          value={this.state.name}
          />
          <Form.Text className="text-muted">Give this recommendation list a name.</Form.Text>

        </Form.Group>
        </Form>
        <Button variant="dougBlue" className="btn-danger"
        onClick={this.createExperience}
        >submit</Button>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  geoLocation:state.location
});

export default connect(mapStateToProps,{})(CreateRecommendationList)
