import React from 'react';
import {baseUrl, imageUrl, formsUrl,authUrl,imageuploadUrl} from '../config.js';
import axios from 'axios';
import store from '../redux/store.js';
import moment from 'moment';
import setAuthToken from './setAuthToken';
import {updateTokens} from '../redux/actions/authActions';
import { v4 as uuidv4 } from 'uuid';
import jwt_decode from "jwt-decode";


class API {
   fetch({method,endpoint,data}) {
     return new Promise(function(resolve,reject) {
       var now = moment().unix();
       var exp_time = store.getState().auth.user.exp;
       console.log('minutes to expire',(exp_time - now)/60);
       console.log('API FETCH')
       if(exp_time <= now) {
         console.log('session expired')
         axios({
           method:'POST',
           url:authUrl + '/auth/refresh-token',
           data:{
             refreshToken: store.getState().auth.refresh_token
           }
         })
         .then(res=>{
           console.log('the res',res.data)
           var decoded = jwt_decode(res.data.access_token)
           localStorage.setItem("dougsuperlogin",JSON.stringify({decoded:decoded,access_token:res.data.access_token,refresh_token:res.data.refresh_token}))
 
           store.dispatch({
             type:'REFRESH_CURRENT_USER',
             payload:res.data
           });
           axios({
             method:method,
             url:baseUrl + endpoint,
             data:data,
             mode: 'cors',
             headers: {
               'Access-Control-Allow-Origin': '*',
               Accept: 'application/json',
               'Content-Type': 'application/json',
               //"Authorization": "Bearer " + store.getState().auth.user.signInUserSession.accessToken.jwtToken
               "Authorization": "Bearer " + res.data.access_token
             },
           }).then(result=>{
             resolve(result);
           }).catch(error=>{
               reject(error);
           })
         })
         .catch(error=>{
           reject(error);
         })
       } else {
         console.log('session is good for now')
         console.log(exp_time - now);
         console.log(store.getState().auth.access_token);
         console.log('data object ', data)
         console.log('fetching from ',baseUrl+endpoint)
         axios({
           method:method,
           url:baseUrl + endpoint,
           data:data,
           mode: 'cors',
           headers: {
             'Access-Control-Allow-Origin': '*',
             Accept: 'application/json',
             'Content-Type': 'application/json',
             "Authorization": "Bearer " + store.getState().auth.access_token
           }
         }).then(result=>{
           resolve(result);
         }).catch(error=>{
           console.log('error was',error)
             reject(error);
         })

       }
     })
  }

  forms({method,endpoint,data}) {
    return new Promise(function(resolve,reject) {
      var now = moment().unix();
      var exp_time = store.getState().auth.user.exp;
      console.log('minutes to expire',(exp_time - now)/60);
      console.log('API FETCH')
      if(exp_time <= now) {
        console.log('session expired')
        axios({
          method:'POST',
          url:authUrl + '/auth/refresh-token',
          data:{
            refreshToken: store.getState().auth.refresh_token
          }
        })
        .then(res=>{
          console.log('the res',res.data)
          var decoded = jwt_decode(res.data.access_token)
          localStorage.setItem("dougsuperlogin",JSON.stringify({decoded:decoded,access_token:res.data.access_token,refresh_token:res.data.refresh_token}))
          store.dispatch({
            type:'REFRESH_CURRENT_USER',
            payload:res.data
          });          axios({
            method:method,
            url:formsUrl + endpoint,
            data:data,
            mode: 'cors',
            headers: {
              'Access-Control-Allow-Origin': '*',
              Accept: 'application/json',
              'Content-Type': 'application/json',
              //"Authorization": "Bearer " + store.getState().auth.user.signInUserSession.accessToken.jwtToken
              "Authorization": "Bearer " + res.accessToken.jwtToken
            },
          }).then(result=>{
            resolve(result);
          }).catch(error=>{
              reject(error);
          })
        })
        .catch(error=>{
          reject(error);
        })
      } else {
        console.log('session is good for now')
        console.log(exp_time - now);
        console.log(store.getState().auth.access_token);
        console.log('data object ', data)
        axios({
          method:method,
          url:formsUrl + endpoint,
          data:data,
          mode: 'cors',
          headers: {
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + store.getState().auth.access_token
          }
        }).then(result=>{
          resolve(result);
        }).catch(error=>{
          console.log('error was',error)
            reject(error);
        })

      }
    })
 }

 image({endpoint,file}) {
   console.log('api stuff',endpoint,file);
   return new Promise(function(resolve,reject) {
     var now = moment().unix();
     var exp_time = store.getState().auth.user.exp;
     console.log('the image file object',file);
     var extension = file.name.split('.').pop();
     console.log('the image extension is',extension);
     if(exp_time <= now) {
      console.log('RUNNIGN THE REFRESH VERSION!')
       console.log('session expired')
       axios({
         method:'POST',
         url:authUrl + '/auth/refresh-token',
         data:{
           refreshToken: store.getState().auth.refresh_token
         }
       })
       .then(res=>{
         console.log('the res',res.data)
         var decoded = jwt_decode(res.data.access_token)
         localStorage.setItem("dougsuperlogin",JSON.stringify({decoded:decoded,access_token:res.data.access_token,refresh_token:res.data.refresh_token}))

         store.dispatch({
           type:'REFRESH_CURRENT_USER',
           payload:res.data
         });
         console.log('running the iamge thing')
         console.log(imageUrl + endpoint);
         var mimetype = "image/jpeg"
         const axiosConfig = {
           headers: {
             'Content-Type': 'multipart/form-data',
           },
         };
         const formData = new FormData();
         formData.append('image', file)
       // formData.append('image',final._data,final._data.name)
         formData.append('endpoint',endpoint)
         axios.post(imageuploadUrl + '/image/superupload',formData, axiosConfig).then(result=>{
           console.log('THE NEW API RESULT',result.data)
           resolve(result.data)
         }).catch(error=>{
           reject(error)
         })
        //  const params = {
        //    Bucket: BUCKET_NAME,
        //    Key: endpoint + uuidv4() + '.' + extension, // File name you want to save as in S3
        //    Body: file,
        //    ContentType:'image/jpeg'
        //  };
        //  s3.upload(params, function(err, data) {
        //       if (err) {
        //         console.log('ERROR',err);
        //           reject(err);
        //       }
        //       console.log(`File uploaded successfully. ${data.Location}`);
        //       console.log(data);
        //       resolve(data);
        //   });


       })
       .catch(error=>{
         reject(error);
         console.log('some kinf od API error')
       })
     } else {
      console.log('RUNNING THE NON REFRESH')
        var mimetype = "image/jpeg"
        const axiosConfig = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
        const formData = new FormData();
        formData.append('image', file)
      // formData.append('image',final._data,final._data.name)
        formData.append('endpoint',endpoint)
        axios.post(imageuploadUrl + '/image/superupload',formData, axiosConfig).then(result=>{
          console.log('THE NEW API RESULT',result.data)
          resolve(result.data)
        }).catch(error=>{
          reject(error)
        })

      //  const params = {
      //    Bucket: BUCKET_NAME,
      //    Key: endpoint + uuidv4() + '.' + extension, // File name you want to save as in S3
      //    Body: file,
      //    ContentType:'image/jpeg'
      //  };
      //  s3.upload(params, function(err, data) {
      //       if (err) {
      //         console.log('ERROR',err);
      //           reject(err);
      //       }
      //       console.log(`File uploaded successfully. ${data.Location}`);
      //       console.log(data);
      //       resolve(data);
      //   });



     }
   })
}

}
export default new API();
