import {
  SET_LOCATION,
  CLEAR_LOCATION,
  SET_DISTANCE
} from './types';


export const setLocation = coords => {
  return {
    type:SET_LOCATION,
    payload:coords
  }
}

export const clearLocation = () => {
  return {
    type:CLEAR_LOCATION
  }
}

export const setDistance = distance => {
  return {
    type:SET_DISTANCE,
    payload:distance
  }
}
