import React, { Component } from 'react';
import { connect } from "react-redux";
import API from '../utils/api';
import ListGroup from 'react-bootstrap/ListGroup';
import UserPoints from './UserPoints';
import moment from 'moment';


class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersloading:true,
      showpoints:false
    }
  }
  componentDidMount() {
    this.setState({
      usersloading:true
    })
    API.fetch({
      method:'GET',
      endpoint:'/admin/users/list/'
    }).then(res=>{
      this.setState({
        users:res.data.reverse(),
        usersloading:false
      })
      console.log(res.data)
    }).catch(error=>{
      console.log(error)
      this.setState({
        usersloading:false,
        error:true,
        users:[]
      })
    })
  }
  getpoints = (user) => {
    var totalpoints = 0;
    user.pointsDoc.map(rew=>{
      totalpoints = totalpoints + rew.pointValue;
    })
    return totalpoints;
  }
  getrewards = (user) => {
    var rewardcount = 0;
    user.rewards.map(rew=>{
      console.log(rew)
      rewardcount = rewardcount + rew.timesUsed;
    })
    return rewardcount;
  }
  render() {
    console.log('user list',this.state);
    return this.state.usersloading ? (
      <div className="container">
      loading
      </div>
    ) : (
      <div className="container">
        <div className="userList">
        <ListGroup>
          {this.state.users.map(user=>{
            return (
              <ListGroup.Item key={user._id} className="userRow"
                style={{
                  cursor:'pointer'
                }}
                onClick={()=>{
                this.props.history.push('/user/'+user._id);
              }}
              >
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
              <img src={user.tinyBase64ProfileImage} style={{borderRadius:25,width:50,height:50,marginRight:10}}/>
              <div style={{marginRight:10,width:'20%'}}>{user.userName}</div>
              <div style={{marginRight:10, width:'20%'}}>{user.homeLocation?.name}</div>
              <div style={{marginRight:10, width:'20%'}}>Joined {moment(user.createdAt).fromNow()}</div>
              <div style={{marginRight:10, width:'15%'}}>{user.vibesdoc?.length} vibes...</div>
              <div style={{marginRight:10, width:'15%'}}>{this.getpoints(user)} points...</div>
              <div style={{marginRight:10, width:'10%'}}>{this.getrewards(user)} rewards...</div>
              </div>
              </ListGroup.Item>
            )
          })}
          </ListGroup>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  geoLocation:state.location
});

export default connect(mapStateToProps,{})(UserList)
