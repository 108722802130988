import React, {Component} from "react";
import { connect } from "react-redux";
import Lottie from 'lottie-react';
import loaderAnimation from '../assets/doug_loader.json'

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return (
      <div>
      <Lottie
        animationData={loaderAnimation}
        style={{
          width:40,
          height:40
        }}
        autoplay
        loop
      /></div>
    )
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps,{})(Loader);
