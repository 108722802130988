export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const USER_NOT_LOADING = "USER_NOT_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const UPDATE_TOKENS = "UPDATE_TOKENS";
export const REFRESH_CURRENT_USER = "REFRESH_CURRENT_USER";

export const FETCH_MAP_PLACES_STARTED = "FETCH_MAP_PLACES_STARTED";
export const FETCH_MAP_PLACES_SUCCESS = "FETCH_MAP_PLACES_SUCCESS";
export const FETCH_MAP_PLACES_ERROR = "FETCH_MAP_PLACES_ERROR";

export const FETCH_LIST_PLACES_STARTED = "FETCH_LIST_PLACES_STARTED";
export const FETCH_LIST_PLACES_ERROR = "FETCH_LIST_PLACES_ERROR";
export const FETCH_LIST_PLACES_SUCCESS = "FETCH_LIST_PLACES_SUCCESS";
export const FETCH_MORE_LIST_PLACES_STARTED = "FETCH_MORE_LIST_PLACES_STARTED";
export const FETCH_MORE_LIST_PLACES_ERROR = "FETCH_MORE_LIST_PLACES_ERROR";
export const FETCH_MORE_LIST_PLACES_SUCCESS = "FETCH_MORE_LIST_PLACES_SUCCESS";

export const SET_LOCATION = "SET_LOCATION";
export const CLEAR_LOCATION = "CLEAR_LOCATION";
export const SET_DISTANCE = "SET_DISTANCE";
export const SET_RADIUS = "SET_RADIUS";
export const SET_ACTIVE = "SET_ACTIVE";

//socket actions
export const SOCKET_TEST = "SOCKET_TEST";
