import React, { Component } from 'react';
import { connect } from "react-redux";
import API from '../utils/api';
import ListGroup from 'react-bootstrap/ListGroup';
import {Table} from 'react-bootstrap';
import Loader from '../utils/loader';
import AsyncSelect from 'react-select/async';
import _ from "lodash";
import fetchs3image from '../utils/fetchs3';
import { FaMapMarker } from "react-icons/fa";
import {
  setLocation,
  clearLocation
} from '../redux/actions/locationActions';

import {
  fetchListPlaces,
  fetchMoreListPlaces
} from '../redux/actions/placeActions';

class ExperiencesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true
    }
  }
  componentDidMount() {
    API.fetch({
      method:'GET',
      endpoint:'/experiences/list'
    }).then(res=>{
      this.setState({
        experiences:res.data,
        loading:false
      })
    }).catch(error=>{
      console.log('error',error)
    })
  }

  render() {
    console.log(this.state)
    return this.state.loading ? (
      <div className="container">
      loading
      </div>
    ) : (
      <div className="container">
      <Table striped bordered hover>
        <thead>
        <th>name</th>
        <th>shortDesc</th>
        <th>description</th>
        <th>image</th>
        </thead>
        <tbody>
        {this.state.experiences.map(exp=>{
          return (
            <tr key={exp._id}
              onClick={()=>{
              this.props.history.push('/experience/'+exp._id);
              }}
            >
            <td>{exp.name}</td>
            <td>{exp.shortDescription}</td>
            <td>{exp.description}</td>
            <td><img src={fetchs3image({bucket:exp.image?.s3image?.bucket,key:exp.image?.s3image?.key})} width={75}/></td>
            </tr>
          )
        })}
        </tbody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  geoLocation:state.location,
  places:state.mapplaces
});

export default connect(mapStateToProps,{setLocation,clearLocation,fetchListPlaces,fetchMoreListPlaces})(ExperiencesList)
