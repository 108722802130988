import React, { Component } from 'react';
import { connect } from "react-redux";
import API from '../utils/api';
import ListGroup from 'react-bootstrap/ListGroup';
import Loader from '../utils/loader';
import AsyncSelect from 'react-select/async';
import _ from "lodash";
import { FaMapMarker } from "react-icons/fa";
import {
  setLocation,
  clearLocation
} from '../redux/actions/locationActions';
import {baseUrl} from '../config.js';

import {
  fetchListPlaces,
  fetchMoreListPlaces
} from '../redux/actions/placeActions';

class AddedPlaceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placesloading:true,
        places:[]
    }
  }
  componentDidMount() {
    this.setState({
      placesloading:true
    })
    API.fetch({
        method:'GET',
        endpoint:'/addaplace/list'
    }).then(res=>{
        this.setState({
            places:res.data,
            placesloading:false
        })
    })
}

  render() {
    console.log(this.state)
    return this.state.placesloading ? (
      <div className="container">
      loading
      </div>
    ) : (
      <div className="container">
        <div className="userList" style={{marginTop:15}}>
        <ListGroup>
          {this.state.places.map((place,index)=>{
            return (
              <>
              <ListGroup.Item key={place._id} className="userRow"
                style={{
                  cursor:'pointer',
                  display:'flex',
                  flexDirection:'row'
                }}
                onClick={()=>{
                this.props.history.push('/place/'+place._id);
              }}>
              <div style={{
                flex:1
              }}>
              {index + 1} - {place.name} added by {place.userObject?.userName}
              </div>
              <div style={{
                width:20,
                height:20,
                borderRadius:10,
                backgroundColor:place.internalReviewStatus === "new" ? "red" : place.internalReviewStatus === "needsreview" ? "yellow" : place.internalReviewStatus === "completedGood" ? "green" : "blue"
              }}></div>
              {place.status == 'disabled' &&
              <div>disabled</div>
            }
              </ListGroup.Item>
              </>
            )
          })}
          </ListGroup>

        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  geoLocation:state.location,
  places:state.mapplaces
});

export default connect(mapStateToProps,{setLocation,clearLocation,fetchListPlaces,fetchMoreListPlaces})(AddedPlaceList)
