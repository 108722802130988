import React, { Component } from 'react';
import ReactMapboxGl, { Layer, Feature, Marker, Cluster, Popup } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import axios from 'axios';
import {mapboxToken} from '../config.js';
import heart from '../assets//heart.png';
import Loading from '../utils/loader';
import Fetchs3image from '../utils/fetchs3'
const latitude = 38.527951;
const longitude = -95.169377;



export default class ImageLoad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageLoaded:null,
      loading:true
    }
  }
  fetchs3image(s3image) {
    console.log('the image we got is', s3image)
    var url = process.env.REACT_APP_S3_URL;
    var obj = {
      "bucket": s3image.Bucket,
      "key": s3image.Key,
      "edits": {
        "resize": {
          "width": 200,
          "fit": "cover"
        }
      }
    }
    var encoded = btoa(JSON.stringify(obj));
    var imageUrl = url + encoded;
    return imageUrl;
  }
  componentDidUpdate(prevProps) {
    if(prevProps.src != this.props.src) {
      this.setState({
        loading:true,
        imageLoaded:null
      })
      console.log('the props are',this.props)
      const {src} = this.props;
      console.log('the source',src)
      const newsrc = this.fetchs3image(src);
      console.log('the new src',newsrc)
      const imageLoader = new Image();
      imageLoader.src = newsrc;
      imageLoader.onload = () => {
        this.setState({ imageLoaded:newsrc,loading:false });
      };

    }
  }
  componentDidMount() {
    console.log('the props are',this.props)
    const {src} = this.props;
    console.log('the source',src)
    const newsrc = this.fetchs3image(src);
    console.log('the new src',newsrc)
    const imageLoader = new Image();
    imageLoader.src = newsrc;
    imageLoader.onload = () => {
      this.setState({ imageLoaded:newsrc,loading:false });
    };
  }
  render() {
    console.log('the state is',this.state)
    return (
        <div style={{
          width:200,
          height:110,
          backgroundImage:`url(${this.state.imageLoaded})`,
          backgroundSize:'cover',
          display:'flex',
          justifyContent:'center',
          alignItems:'center'
        }}

        >{this.state.loading && <Loading/>}
        </div>
    )
  }
}
