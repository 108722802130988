import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from '../../utils/loader';
import API from '../../utils/api';
import fetchs3image from '../../utils/fetchs3';
import autosize from 'autosize';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import {baseUrl} from '../../config.js';
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import {mapboxToken} from '../../config.js';
import axios from 'axios';
import {BsXCircleFill} from 'react-icons/bs';

import "react-datepicker/dist/react-datepicker.css";

const Map = ReactMapboxGl({
  accessToken: mapboxToken,
});

class AddCharity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name:'',
      description:'',
      tagline:'',
      imageUploading:false,
      image: {
        s3image : null
      },
      location: {
        type: 'Point',
        coordinates: [ -105.57218881104323, 36.41869591878862 ],
        name: 'Taos, New Mexico, United States',
        city: 'Taos',
        country: 'United States',
        postcode: '87571',
        state: 'New Mexico'
      },
      features:[]
    }
    this.updateDragLocation = this.updateDragLocation.bind(this);

  }
  search = (search) => {
    var searchtext = encodeURIComponent(search) + '.json';
    axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + searchtext, {
      params: {
        access_token : process.env.REACT_APP_MAPBOX_TOKEN,
        cachebuster : '1578867295345',
        autocomplete: false,
        country:'us',
        types:'address,poi,neighborhood,locality,postcode,district,place'
      }
    }).then(res=>{
      console.log(res)
      this.setState({
        features:res.data.features
      })
    }).catch(error=>{
      console.log('error!',error)
    })
  }
  async updateDragLocation(coords) {
    try {
      const locationName = await axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + coords[0]+','+coords[1]+'.json', {
        params: {
          access_token : process.env.REACT_APP_MAPBOX_TOKEN,
          cachebuster : '1578867295345',
          autocomplete: false,
          types:'place'
        }
      })
      console.log('the location name is', locationName)
      this.setState({
        location : {...this.state.location, coordinates:[coords[0],coords[1]],name:locationName.data.features[0].place_name}
      })

    } catch(error) {
      console.log('error updating place', error);
      alert('error!')
    }
    //get new place name.
  }
  manualupdate = () => {
    console.log('running manual ')
    if(!this.state.newlat || !this.state.newlong) {
      alert('need to set lat/lng');
      return;
    }
    console.log('setting')
    this.setState({
      location : {...this.state.location, coordinates:[this.state.newlong,this.state.newlat],name:""}
    })

  }
  setNewPoints = (feature) => {
    console.log('featyre',feature);
    let city,state,postcode;
    feature.context.forEach((v,i) => {
      if (v.id.indexOf("postcode") >= 0) {
        postcode = v.text;
      }
      if (v.id.indexOf("place") >= 0) {
        city = v.text;
      }
      if (v.id.indexOf("region") >= 0) {
        state = v.text;
      }


    })
    this.setState({
      location: {...this.state.location,city:city,postcode:postcode,state:state}
    })
    console.log('new c/s/p', city,state,postcode)
    this.updateDragLocation(feature.geometry.coordinates)
    this.setState({
      features:[],
      search:''
    })
  }
  image_upload() {
    this.setState({
      imageUploading:true
    })
    const file = document.getElementById('locationImage').files[0]
    //file: document.getElementById('locationImage').files[0]
    API.image({
      endpoint:'places/',
      file:file

    })
    .then((result)=>{
      console.log('the charity image',result)
        this.setState({
          image: {
              ...this.state.image, s3image:result
            },
          imageUploading:false
          })
      })
      .catch((error)=>{
        this.setState({
          imageUploading:false
        })
      })
    }

    createcharity = () => {
      if(
        !this.state.name ||
        !this.state.tagline ||
        !this.state.description ||
        !this.state.image
        ) {
        alert('please fill everything out')
        return;

      } else {

        const requestBody = {
          name: this.state.name,
          description: this.state.description,
          tagline: this.state.tagline,
          image:this.state.image,
          location:this.state.location
        }
        const config = {
          headers : {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
        API.fetch({
          method:'POST',
          endpoint:'/super/charity/new',
          data:this.state
        }).then(res=>{
          console.log('charity created ',res)
          this.props.history.push('/charity/list')
        }).catch(error=>{
          console.log('error',error)
          alert('error')
        })

      }
    }

  render() {
    console.log(this.state)
    return (
      <Container>
        <Form style={{marginBottom:75}}>
        <Form.Group className="businessProfileFormGroupMargin">
          <Form.Label>Charity Name</Form.Label>
          <Form.Control type="text" placeholder="business name"
          onChange={(e)=>{
            this.setState({
              name:e.target.value
            })
          }}
          value={this.state.name}
          />
        </Form.Group>
        <Form.Group className="businessProfileFormGroupMargin">
          <Form.Label>Charity Description</Form.Label>
          <Form.Control as="textarea" placeholder="description"
          rows={5}
          ref={this.textAreaRef}
          onChange={(e)=>{
            this.setState({
              description:e.target.value
            })
          }}
          value={this.state.description}
          />
          <Form.Text className="text-muted">Describe exactly how the reward works</Form.Text>
        </Form.Group>
        <Form.Group className="businessProfileFormGroupMargin">
          <Form.Label>Charity Tagline</Form.Label>
          <Form.Control type="text" placeholder="business name"
          onChange={(e)=>{
            this.setState({
              tagline:e.target.value
            })
          }}
          value={this.state.tagline}
          />
        </Form.Group>

        {this.state.image?.s3image ? (
          <div
            style={{
              width:600,
              height:400,
              background:`url(${this.state.image?.s3image ? fetchs3image(this.state.image?.s3image) : null}) center / cover`,
              backgroundSize:'cover',
              backgroundPosition:'center'
            }}
            className="profilePicHolder"
          >
          {this.state.imageUploading ? (
              <div className="profilePicUploadingTrue">
              <Loader/>
              </div>
          ) : (
            <div className="profilePicUploadButton">
            <input
              className="dougInput"
              id="locationImage"
              type="file"
              accept="image/*"
              className={this.state.location ? "" : "form-control"}
              style={{border:0,boxShadow:"0"}}
              onChange={(e)=>{this.image_upload()}}
              />
              </div>

          )}

          </div>

        ) : (
          <div className="">
          <div>Charity Image</div>
          <input
            className="dougInput"
            id="locationImage"
            type="file"
            accept="image/*"
            className={this.state.location ? "" : "form-control"}
            style={{border:0,boxShadow:"0"}}
            onChange={(e)=>{this.image_upload()}}
            />
            </div>
        )
        }


        </Form>
        <div className="businessProfileMap">
              <Map
                style="mapbox://styles/mapbox/streets-v9"
                containerStyle={{
                  height:'500px',
                  width: '100%'
                }}
                zoom={[15]}
                center={[this.state.location?.coordinates[0],this.state.location?.coordinates[1]]}
              >
                <Layer type="symbol" id="marker" layout={{ 'icon-image': 'circle-15' }}>
                  <Feature
                    coordinates={[this.state.location?.coordinates[0],this.state.location?.coordinates[1]]}
                    draggable={true}
                    onDragStart={(e)=>{console.log(e);}}
                    onDragEnd={(e)=>{
                      console.log(e.lnglat)
                      //var templocation = this.state.location.coordinates;
                      var templocation = []
                      templocation[0] = e.lngLat.lng;
                      templocation[1] = e.lngLat.lat;
                      console.log('HERE',templocation);
                      this.updateDragLocation(templocation);
                      //
                      // this.setState({
                      //   templocation
                      // })
                    }}
                  />
                </Layer>
              </Map>
              <div className="businessProfileSearchBar">
                <div className="bpbarwrap"
                  style={{position:'relative'}}
                >
                  <Form.Control type="text" placeholder="search for an address"
                  onChange={(e)=>{
                    this.setState({
                      search:e.target.value
                    })
                    this.text = e.target.value;
                    clearTimeout(this.timeout);
                    this.timeout = setTimeout(()=>this.search(this.text),500)
                  }}
                  value={this.state.search}
                  />
                  <BsXCircleFill style={{
                    position: 'absolute',
                    right: 7,
                    top: 11,
                    cursor:'pointer',
                    color:'rgb(94,94,94)'
                  }}
                  onClick={()=>{
                    this.setState({
                      search:'',
                      features:[]
                    })
                  }}
                  />
                </div>
              <div className="businessProfileSearchResultsWrap">
                {this.state.features.map(feat=>{
                  return (
                    <div
                    className="businessProfileSearchResultItem"
                    onClick={()=>{this.setNewPoints(feat)}}
                    >{feat.place_name}</div>
                  )
                })}
              </div>

              </div>
              <div style={{
                display:'flex',
                flexDirection:'row'
              }}>
              <input
              style={{flex:1}}
              value={this.state.newlat}
              onChange={(e)=>{
                this.setState({
                  newlat:e.target.value
                })
              }}
              placeholder="latitude"
              />
              <input
              style={{flex:1}}
              value={this.state.newlong}
              onChange={(e)=>{
                this.setState({
                  newlong:e.target.value
                })
              }}
              placeholder="longitutde"
              />
              <button
                onClick={()=>{
                  if(!this.state.newlat || !this.state.newlong) {
                    alert('need a lat/lng');
                    return;
                  }
                  var templocation = []
                  templocation[0] = this.state.newlong
                  templocation[1] = this.state.newlat;
                  console.log('HERE',templocation);
                  this.updateDragLocation(templocation);
                }}
              >update</button>
              </div>
          </div>
        <Button variant="dougBlue" className="btn-danger"
        onClick={this.createcharity}
        >submit</Button>
      </Container>
    )
  }
}


const mapStateToProps = state => ({
  auth: state.auth,
  business:state.business,
  places:state.mapplaces,
  geoLocation:state.location

});

export default connect(mapStateToProps,{})(AddCharity)
