//const baseUrl = 'http://localhost:3021';
//const authUrl = 'http://localhost:3020';
//const imageuploadUrl = 'http://localhost:3021';
const authUrl = 'https://auth2.douglovesyou.com';
const baseUrl = 'https://api2.douglovesyou.com';
const imageUrl = 'https://secureimage.douglovesyou.com';
const imageuploadUrl = 'https://api2.douglovesyou.com';
const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN_PRIVATE;
const formsUrl = 'https://5173392287westphalia.douglovesyou.com';
//const formsUrl = 'http://localhost:3010';
export { baseUrl,imageUrl, mapboxToken, formsUrl, authUrl, imageuploadUrl }
