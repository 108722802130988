import React, { Component } from 'react';
import { connect } from "react-redux";
import {loginUser,logoutUser} from '../redux/actions/authActions';
import API from '../utils/api'
import {authUrl} from '../config.js';
import axios from 'axios';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email:'',
      password:''
    }
  }
  loginuser = (e) => {
    e.preventDefault();
    this.setState({
      submitting:true
    })
    const endpoint = authUrl + '/auth/loginwithemail';
    console.log('the endpoint',endpoint)
    axios({
      method:'POST',
      url:endpoint,
      data:{
        email:this.state.email
      }
    }).then(res=>{
      console.log('the new auth res',res)
      this.setState({
        code:res.data
      })

      //this.props.navigation.navigate('EmailLogin',{email:this.state.email,code:res.data})
    }).catch(error=>{
      console.log(error)
    })

  }
  verifycode = (e) => {
    e.preventDefault();
    this.setState({
      submitting:true
    })
    const endpoint = authUrl + '/auth/loginwithemail';
    axios({
      method:'POST',
      url: authUrl + '/auth/authemailcode',
      data: {
        email:this.state.email,
        code:this.state.verifycode
      }
    }).then(res=>{
      console.log('the code result',res)
      this.props.loginUser(res.data).then(res=>{
        return
      }).catch(error=>{
        this.setState({
          email:'',
          password:'',
          code:null
        })
      })
    }).catch(error=>{
      console.log(error)
    })
  }
  render() {
    return this.state.code ? (
      <div className="container">
      <div className="row d-flex justify-content-center align-items-center" style={{height:'90vh'}}>
      <div className="col-md-4" style={{
        padding: 25,
        background: '#efefef',
        borderRadius: 11
      }}>

      <form onSubmit={this.verifycode}>
          <h3>Verify Code</h3>
          <div>{this.state.code}</div>
          <div className="form-group">
              <label>Code</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter code"
                value={this.state.verifycode}
                onChange={e=>{this.setState({verifycode:e.target.value})}} />
          </div>
          <button style={{marginTop:10}} type="submit" className="btn btn-danger btn-block">Verify Code</button>
      </form>

      </div>
      </div>
      </div>

    ) : (
      <div className="container">
      <div className="row d-flex justify-content-center align-items-center" style={{height:'90vh'}}>
      <div className="col-md-4" style={{
        padding: 25,
        background: '#efefef',
        borderRadius: 11
      }}>

      <form onSubmit={this.loginuser}>
          <h3>Sign In</h3>
          <div className="form-group">
              <label>Email address</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter email"
                value={this.state.email}
                onChange={e=>{this.setState({email:e.target.value})}} />
          </div>
          <button style={{marginTop:10}} type="submit" className="btn btn-danger btn-block">Log  In</button>
      </form>

      <div className="loginSocialWrap">

      </div>

      </div>
      </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  geoLocation:state.location
});

export default connect(mapStateToProps,{loginUser,logoutUser})(Login)
