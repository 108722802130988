import {
  FETCH_MAP_PLACES_STARTED,
  FETCH_MAP_PLACES_ERROR,
  FETCH_MAP_PLACES_SUCCESS,
  FETCH_LIST_PLACES_STARTED,
  FETCH_LIST_PLACES_ERROR,
  FETCH_LIST_PLACES_SUCCESS,
  FETCH_MORE_LIST_PLACES_STARTED,
  FETCH_MORE_LIST_PLACES_ERROR,
  FETCH_MORE_LIST_PLACES_SUCCESS
} from "../actions/types";
const initialState = {
  mapplaces:[],
  mapplacesloading:true,
  listplaces:[],
  listplacesloading:true,
  listplacespage:0,
  listplacesfetchingmore:false
};
export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_MAP_PLACES_STARTED:
      return {
        ...state,
        mapplacesloading:true
      }
    case FETCH_MAP_PLACES_ERROR:
      return {
        ...state,
        mapplacesloading:false,
        mapplaceserror:action.payload.error
      }
    case FETCH_MAP_PLACES_SUCCESS:
      return {
        ...state,
        mapplacesloading:false,
        mapplaces:action.payload,
      }
    case FETCH_LIST_PLACES_STARTED:
      return {
        ...state,
        listplacesloading:true
      }
    case FETCH_LIST_PLACES_ERROR:
      return {
        ...state,
        listplacesloading:false,
        listplaceserror:action.payload.error
      }
    case FETCH_LIST_PLACES_SUCCESS:
        return {
          ...state,
          listplacesloading:false,
          listplaces:action.payload.places,
          listplacespage:0
        }
    case FETCH_MORE_LIST_PLACES_STARTED:
      return {
        ...state,
        listplacesfetchingmore:true
      }
    case FETCH_MORE_LIST_PLACES_ERROR:
      return {
        ...state,
        listplaceserror:action.payload.error
      }
    case FETCH_MORE_LIST_PLACES_SUCCESS:
      return {
        ...state,
        listplacespage:action.payload.newpage,
        listplaces:[...state.listplaces,...action.payload.places],
        listplacesfetchingmore:false
      }
      default:
        return state;
    }
  }
