import React, { Component, useRef } from 'react';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import Loader from '../utils/loader';
import { connect } from "react-redux";
import API from '../utils/api';
import fetchs3image from '../utils/fetchs3';

class CreatePlace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUploading:false,
      name:'',
      tagline:'',
      description:'',
      image: {
        s3image : null
      },
      location: {
        type:'Point',
        coordinates:this.props.geoLocation.coordinates,
        name:this.props.geoLocation.name
      },
    }
  }
  image_upload() {
    this.setState({
      imageUploading:true
    })
    const file = document.getElementById('locationImage').files[0]
    //file: document.getElementById('locationImage').files[0]
    API.image({
      endpoint:'places/',
      file:file

    })
    .then((result)=>{
        this.setState({
          image: {
              ...this.state.image, s3image:result
            },
          imageUploading:false
          })
      })
      .catch((error)=>{
        this.setState({
          imageUploading:false
        })
      })
    }
    createplace = () => {
      if(
        !this.state.name ||
        !this.state.tagline ||
        !this.state.description ||
        !this.state.image?.s3image
      ) {
        alert('please fill everything out')
        return;

      } else {

        const requestBody = {
          name: this.state.name,
          description: this.state.description,
          tagline: this.state.tagline,
          location:this.state.location,
          image:this.state.image
        }
        const config = {
          headers : {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
        API.fetch({
          method:'POST',
          endpoint:'/admin/places/add',
          data:requestBody
        }).then(res=>{
          console.log('place created ',res)
          this.props.history.push('/place/'+res.data._id)
        }).catch(error=>{
          console.log('error',error)
          alert('error')
        })

      }
    }
  render() {
    return (
      <Container>
        <Form style={{marginBottom:75}}>
        <Form.Group className="businessProfileFormGroupMargin">
          <Form.Label>Business Name</Form.Label>
          <Form.Control type="text" placeholder="business name"
          onChange={(e)=>{
            this.setState({
              name:e.target.value
            })
          }}
          value={this.state.name}
          />
          <Form.Text className="text-muted">Business name.</Form.Text>
        </Form.Group>

        <Form.Group className="businessProfileFormGroupMargin">
          <Form.Label>Tagline</Form.Label>
          <Form.Control type="text" placeholder="tagline"
          onChange={(e)=>{
            this.setState({
              tagline:e.target.value
            })
          }}
          value={this.state.tagline}
          />
          <Form.Text className="text-muted">A quick way to describe your business.</Form.Text>
        </Form.Group>
        <Form.Group className="businessProfileFormGroupMargin">
          <Form.Label>Description</Form.Label>
          <Form.Control as="textarea" placeholder="description"
          rows={5}
          ref={this.textAreaRef}
          onChange={(e)=>{
            this.setState({
              description:e.target.value
            })
          }}
          value={this.state.description}
          />
          <Form.Text className="text-muted">The main description of your business.</Form.Text>
        </Form.Group>
        {this.state.image?.s3image ? (
          <div
            style={{
              width:600,
              height:400,
              background:`url(${this.state.image?.s3image ? fetchs3image(this.state.image?.s3image) : null}) center / cover`,
              backgroundSize:'cover',
              backgroundPosition:'center'
            }}
            className="profilePicHolder"
          >
          {this.state.imageUploading ? (
              <div className="profilePicUploadingTrue">
              <Loader/>
              </div>
          ) : (
            <div className="profilePicUploadButton">
            <input
              className="dougInput"
              id="locationImage"
              type="file"
              accept="image/*"
              className={this.state.location ? "" : "form-control"}
              style={{border:0,boxShadow:"0"}}
              onChange={(e)=>{this.image_upload()}}
              />
              </div>

          )}

          </div>

        ) : (
          <div className="">
          <input
            className="dougInput"
            id="locationImage"
            type="file"
            accept="image/*"
            className={this.state.location ? "" : "form-control"}
            style={{border:0,boxShadow:"0"}}
            onChange={(e)=>{this.image_upload()}}
            />
            </div>
        )
        }


        </Form>
        <Button variant="dougBlue" className="btn-danger"
        onClick={this.createplace}
        >submit</Button>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  geoLocation:state.location
});

export default connect(mapStateToProps,{})(CreatePlace)
