import { combineReducers } from "redux";
import authReducer from "./authReducers";
import locationReducer from './locationReducer';
import placeReducers from './placeReducers';

export default combineReducers({
  auth: authReducer,
  location:locationReducer,
  mapplaces: placeReducers
});
