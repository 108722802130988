import React, { Component } from 'react';
import { connect } from "react-redux";
import API from '../utils/api';
import ListGroup from 'react-bootstrap/ListGroup';
import Loader from '../utils/loader';
import fetchs3image from '../utils/fetchs3';
import Lottie from 'lottie-react';
import moment from 'moment';
import { saveAs } from 'file-saver';
class SingleVibeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleted:false
    }
  }
  componentDidMount() {
  }
  render() {
    console.log(this.props)
    const {vibe} = this.props;
    return this.state.deleted ? null : vibe.actionType == "vibed" ? (
      <div className="singleVibeWrap">
        <div className="singleVibeUserBar">
          <div className="singleVibeUserBarLeft">
            <div className="singleVibeUserImage"
              style={{
                background:"url('"+vibe.userDoc[0].tinyBase64ProfileImage+"')",
                backgroundSize:'cover',
                cursor:'pointer'
              }}
              onClick={()=>{
                this.props.history.push('/user/'+vibe.userDoc[0]._id);
              }}
            >
            </div>
          </div>
          <div className="singleVibeUserBarRight">
            <div className="singleVibeUserName">
            <span
            style={{
              cursor:'pointer',
              marginRight:5
            }}
            onClick={()=>{
              this.props.history.push('/user/'+vibe.userDoc[0]._id);
            }}

            >{vibe.userDoc[0].userName}</span> vibed {vibe.vibeDoc[0].vibeTitle}
            <Lottie
              animationData={JSON.parse(vibe.vibeDoc[0].lottie)}
              style={{
                width:40,
                height:40,
                marginRight:8
              }}
              autoplay
              loop
            />
            @{vibe.placeDoc[0].name}
            </div>
            <div className="singleVibeLocation">{vibe.location.name}</div>
            <div className="singleVibeTime">{moment(vibe.createdAt).fromNow()}</div>
              <div className="singleVibeId"
              onClick={()=>{
                console.log(vibe._id)
                API.fetch({
                  method:'POST',
                  endpoint:'/admin/activities/delete/' + vibe._id
                }).then(result=>{
                  console.log('delete',result)
                }).catch(error=>{
                  console.log('error delte',error)
                })
              }}
              >{vibe._id}</div>
          </div>
        </div>
      {vibe.vibeImage &&
        <div className="vibeImageWrap" style={{
          background:"url("+fetchs3image(vibe.vibeImage)+")",
          backgroundSize:'cover'
        }}>
        <div className="singleVibeDownloadButtonWrap">
          <button className="btn btn-danger btn-block"
          onClick={()=>{
            console.log(fetchs3image(vibe.vibeImage))
            saveAs(fetchs3image(vibe.vibeImage), vibe.userDoc[0].userName.replace(/\s/g, '') + vibe.placeDoc[0].name.replace(/\s/g, '')) // Put your image url here.
          }}
          >Download Image</button>
        </div>
        </div>

      }
      {vibe.vibeComment &&
        <div className="singleVibeCommentWrap">
          {vibe.vibeComment}
        </div>
      }
      </div>
    ) : vibe.actionType == "redeemed a reward" ? (
      <div className="singleVibeWrap">
      <div>{vibe.userDoc[0].userName} redeemed a reward @ {vibe.placeDoc[0].name}</div>
      <div>{vibe.rewardRecord.rewardTitle}</div>
      </div>
    ) : (
      <div>unknown vibe type</div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  geoLocation:state.location
});

export default connect(mapStateToProps,{})(SingleVibeView)
