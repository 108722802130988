import React, { Component } from 'react';
import { connect } from "react-redux";
import API from '../utils/api';
import ListGroup from 'react-bootstrap/ListGroup';
import {Table} from 'react-bootstrap';
import Loader from '../utils/loader';
import AsyncSelect from 'react-select/async';
import _ from "lodash";
import { FaMapMarker } from "react-icons/fa";
import {
  setLocation,
  clearLocation
} from '../redux/actions/locationActions';
import moment from 'moment';
class FormsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formsloading:true,
      page:0,
      loadingmore:false,
      searchVisible:false,
    }
  }
  componentDidMount() {
    this.setState({
      formsloading:true
    })
    API.forms({
      method:'GET',
      endpoint:'/list/forms/',
    }).then(res=>{
      this.setState({
        forms:res.data,
        formsloading:false
      })
      console.log(res.data)
    }).catch(error=>{
      console.log(error)
      this.setState({
        formsloading:false,
        error:true,
        forms:[]
      })
    })
  }

  render() {
    return this.state.formsloading ? (
      <div className="container">
      loading
      </div>
    ) : this.state.error ? (
      <div>error</div>
    ) :(
      <div className="container">
        <div className="userList" style={{marginTop:15}}>
        <Table striped bordered hover>
          <thead>
          <th>age</th>
          <th>contact</th>
          <th>location</th>
          <th>status</th>
          </thead>
          <tbody>
          {this.state.forms.map(form=>{
            return (
              <tr key={form._id}
                onClick={()=>{
                this.props.history.push('/forms/'+form._id);
                }}
              >
              <td>{moment(form.createdAt).fromNow()}</td>
              <td>{form.personName} - {form.businessName}</td>
              <td>{form.city}, {form.state}</td>
              <td style={{backgroundColor:form.status == 'new' ? 'pink' : 'yellow'}}>{form.status}</td>
              </tr>
            )
          })}

          </tbody>
        </Table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  geoLocation:state.location,
});

export default connect(mapStateToProps,{setLocation,clearLocation})(FormsList)
