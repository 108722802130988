import React, { Component, useRef } from 'react';
import { connect } from "react-redux";
import Loader from '../utils/loader';
import API from '../utils/api';
import fetchs3image from '../utils/fetchs3';
import autosize from 'autosize';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { WithContext as ReactTags } from 'react-tag-input';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import {mapboxToken,baseUrl} from '../config.js';
import axios from 'axios';
import {BsXCircleFill} from 'react-icons/bs';
import { saveAs } from 'file-saver';
import AsyncSelect from 'react-select/async';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from "lodash";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const Map = ReactMapboxGl({
  accessToken: mapboxToken,
});

const reorder = (list, startIndex, endIndex) => {
  console.log('THE LIST IS',list)
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 2,
  margin: `0 0 2px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: 2,
  width: 250
});



class EditExperience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      experience:null,
      loading:true,
      tagline:'',
      updating:false,
      features:[]
    }
    this.textAreaRef = React.createRef();
    this._tagHandleDelete = this._tagHandleDelete.bind(this);
    this._tagHandleAddition = this._tagHandleAddition.bind(this);
    this.updateDragLocation = this.updateDragLocation.bind(this);
    this.loadOptionsbounce = _.debounce(this.loadOptions, 350);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.experience.places,
      result.source.index,
      result.destination.index
    );

    this.setState({
      experience:{
        ...this.state.experience,places:items
      }
    });
  }

  componentDidMount() {
    const scrollHeight = this.textAreaRef.current?.scrollHeight;
    console.log('THE SCROLL HEIGHT',scrollHeight)
    console.log(this.textAreaRef);
    API.fetch({
      method:'GET',
      endpoint:'/experiences/list/' + this.props.match.params.id,
    })
    .then(response=>{
      console.log('the experience response',response)
      this.setState({
        experience:response.data,
        loading:false
      })
      autosize(this.textarea);
    })
    .catch(error=>{
      console.log('error',error)
      this.setState({error:true,loading:true})
    })
  }
  loadOptions = (inputValue) => {
    console.log('LOADING NEW STUFF **********')
    console.log('the input value',inputValue)
    //return fetch(`http://jsonplaceholder.typicode.com/posts?userId=${inputValue}`).then(res => res.json());
      return fetch(baseUrl + '/places/unpagedsearch',{
        method:'POST',
        headers : {
          Authorization: "Bearer " + this.props.auth.access_token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({search:inputValue})
      }).then(res=>res.json())
      // return API.fetch({
      //   method:'POST',
      //   endpoint:'/places/unpagedsearch',
      //   data:{
      //     search:inputValue
      //   }
      // }).then(res=>res.json());
  };
  handleChange = (value) => {
    this.setState({
      selectedValue : value
    })
    this.setState({
      experience:{...this.state.experience,places:[...this.state.experience.places,value]}
    })
    //this.props.history.push('/place/'+value._id)
    console.log('the selected value',value)
  }
  async updateDragLocation(coords) {
    try {
      const locationName = await axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + coords[0]+','+coords[1]+'.json', {
        params: {
          access_token : process.env.REACT_APP_MAPBOX_TOKEN,
          cachebuster : '1578867295345',
          autocomplete: false,
          types:'place'
        }
      })
      console.log('the location name is', locationName)
      this.setState({
        experience: {...this.state.experience,location : {...this.state.experience.location, coordinates:[coords[0],coords[1]],name:locationName.data.features[0].place_name}}
      })

    } catch(error) {
      console.log('error updating place', error);
      alert('error!')
    }
    //get new place name.
  }

  image_upload() {
    this.setState({
      imageUploading:true
    })
    const file = document.getElementById('locationImage').files[0]
    //file: document.getElementById('locationImage').files[0]
    API.image({
      endpoint:'places/',
      file:file

    })
    .then((result)=>{
        this.setState({
          experience: {
            ...this.state.experience, image : {
              ...this.state.experience.image, s3image:result
            }
          },
          imageUploading:false
          })
      })
      .catch((error)=>{
        this.setState({
          imageUploading:false
        })
      })
    }
    delete = () => {
      let doit = window.confirm(`are you sure you want to delete ${this.state.experience.name}? this is permanent!`);
      if(doit) {
        var confirm = window.prompt(`please type the place name: ${this.state.experience.name} to delete`);
        if(confirm === this.state.experience.name) {
          API.fetch({
            method:'POST',
            endpoint:'/super/experience/delete',
            data: {
              experienceId:this.state.experience._id
            }
          }).then(res=>{
            alert('deleted!')
            this.props.history.push('/experiences/list');
          }).catch(error=>{
            alert('API error deleting')
          })
        } else {
          alert('incorrect name entered')
        }
      } else {
        console.log('cancelled the delte')
      }
    }
    update = (e) => {
      e.preventDefault();
      if(this.state.updating) {
        return;
      }
      this.setState({
        updating:true
      })
      API.fetch({
        method:'POST',
        endpoint:'/super/experience/update/' + this.state.experience._id,
        data: this.state.experience
      })
      .then(res=>{
        //this.props.updateBusiness(this.state.experience);
        this.setState({
          updating:false
        })
      })
      .catch(error=>{
        this.setState({
          updating:false
        })
      })
    }
    _tagHandleDelete(i) {
        const { tags } = this.state.experience;
        this.setState({
          experience: {...this.state.experience,tags: tags.filter((tag, index) => index !== i)}
        });
    }

    _tagHandleAddition(tag) {
      this.setState(state=>({
        experience:{...state.experience,tags: [...state.experience.tags, tag]}
      }))
    }
    search = (search) => {
      var searchtext = encodeURIComponent(search) + '.json';
      axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + searchtext, {
        params: {
          access_token : process.env.REACT_APP_MAPBOX_TOKEN,
          cachebuster : '1578867295345',
          autocomplete: false,
          country:'us',
          types:'address,poi,neighborhood,locality,postcode,district,place'
        }
      }).then(res=>{
        console.log(res)
        this.setState({
          features:res.data.features
        })
      }).catch(error=>{
        console.log('error!',error)
      })
    }
    setNewPoints = (feature) => {
      console.log('featyre',feature);
      let city,state,postcode;
      feature.context.forEach((v,i) => {
        if (v.id.indexOf("postcode") >= 0) {
          postcode = v.text;
        }
        if (v.id.indexOf("place") >= 0) {
          city = v.text;
        }
        if (v.id.indexOf("region") >= 0) {
          state = v.text;
        }


      })
      this.setState({
        experience: {...this.state.experience, location: {...this.state.experience.location,city:city,postcode:postcode,state:state}}
      })
      console.log('new c/s/p', city,state,postcode)
      this.updateDragLocation(feature.geometry.coordinates)
      this.setState({
        features:[],
        search:''
      })
    }
    manualupdate = () => {
      console.log('running manual ')
      if(!this.state.newlat || !this.state.newlong) {
        alert('need to set lat/lng');
        return;
      }
      console.log('setting')
      this.setState({
        experience: {...this.state.experience,location : {...this.state.experience.location, coordinates:[this.state.newlong,this.state.newlat],name:""}}
      })

    }
    removeExperienceItem = (item) => {
      var placesCopy = Array.from(this.state.experience.places);
      placesCopy.splice(item,1);
      this.setState({
        experience:{...this.state.experience,places:placesCopy}
      })
    }
  render() {
    console.log(this.state)
    return this.state.loading ? (
      <div className="loadScreen"><Loader/></div>
    ) : this.state.error ? (
      <div>error</div>
    ) : this.state.updating ? (
      <div className="loadScreen"><Loader/></div>
    ) : (
      <div className="mainContainer top-buffer" style={{marginBottom:75}}>
      <Container fluid>
        <Row>
          <Col xl={5}>
          <div
            style={{
              paddingTop:'65%',
              background:`url(${fetchs3image({bucket:this.state.experience.image?.s3image?.bucket,key:this.state.experience.image?.s3image?.key})}) center / cover`,
              backgroundSize:'cover',
              backgroundPosition:'center'
            }}
            className="profilePicHolder"
          >
          {this.state.imageUploading ? (
              <div className="profilePicUploadingTrue">
              <Loader/>
              </div>
          ) : (
            <div className="profilePicUploadButton">
            <input
              className="dougInput"
              id="locationImage"
              type="file"
              accept="image/*"
              className={this.state.location ? "" : "form-control"}
              style={{border:0,boxShadow:"0"}}
              onChange={(e)=>{this.image_upload()}}
              />
              </div>

          )}
          <div style={{bottom:5,right:5,position:'absolute'}}>
            <button className="btn btn-danger btn-block"
            onClick={()=>{
              saveAs(fetchs3image(this.state.experience.image?.s3image), this.state.experience.name.replace(/\s/g, '') ) // Put your image url here.
            }}
            >Download Image</button>
          </div>
          </div>
          <div className="businessProfileMap">
              <Map
                style="mapbox://styles/mapbox/streets-v9"
                containerStyle={{
                  height:'500px',
                  width: '100%'
                }}
                zoom={[15]}
                center={[this.state.experience.location.coordinates[0],this.state.experience.location.coordinates[1]]}
              >
                <Layer type="symbol" id="marker" layout={{ 'icon-image': 'circle-15' }}>
                  <Feature
                    coordinates={[this.state.experience.location.coordinates[0],this.state.experience.location.coordinates[1]]}
                    draggable={true}
                    onDragStart={(e)=>{console.log(e);}}
                    onDragEnd={(e)=>{
                      console.log(e.lnglat)
                      //var templocation = this.state.location.coordinates;
                      var templocation = []
                      templocation[0] = e.lngLat.lng;
                      templocation[1] = e.lngLat.lat;
                      console.log('HERE',templocation);
                      this.updateDragLocation(templocation);
                      //
                      // this.setState({
                      //   templocation
                      // })
                    }}
                  />
                </Layer>
              </Map>
              <div className="businessProfileSearchBar">
                <div className="bpbarwrap"
                  style={{position:'relative'}}
                >
                  <Form.Control type="text" placeholder="search for an address"
                  onChange={(e)=>{
                    this.setState({
                      search:e.target.value
                    })
                    this.text = e.target.value;
                    clearTimeout(this.timeout);
                    this.timeout = setTimeout(()=>this.search(this.text),500)
                  }}
                  value={this.state.search}
                  />
                  <BsXCircleFill style={{
                    position: 'absolute',
                    right: 7,
                    top: 11,
                    cursor:'pointer',
                    color:'rgb(94,94,94)'
                  }}
                  onClick={()=>{
                    this.setState({
                      search:'',
                      features:[]
                    })
                  }}
                  />
                </div>
              <div className="businessProfileSearchResultsWrap">
                {this.state.features.map(feat=>{
                  return (
                    <div
                    className="businessProfileSearchResultItem"
                    onClick={()=>{this.setNewPoints(feat)}}
                    >{feat.place_name}</div>
                  )
                })}
              </div>

              </div>
              <div style={{
                display:'flex',
                flexDirection:'row'
              }}>
              <input
              style={{flex:1}}
              value={this.state.newlat}
              onChange={(e)=>{
                this.setState({
                  newlat:e.target.value
                })
              }}
              placeholder="latitude"
              />
              <input
              style={{flex:1}}
              value={this.state.newlong}
              onChange={(e)=>{
                this.setState({
                  newlong:e.target.value
                })
              }}
              placeholder="longitutde"
              />
              <button
                onClick={()=>{
                  if(!this.state.newlat || !this.state.newlong) {
                    alert('need a lat/lng');
                    return;
                  }
                  var templocation = []
                  templocation[0] = this.state.newlong
                  templocation[1] = this.state.newlat;
                  console.log('HERE',templocation);
                  this.updateDragLocation(templocation);
                }}
              >update</button>
              </div>
          </div>
          </Col>

          <Col xl={7}>
            <Form onSubmit={this.update}>
              <Form.Group className="businessProfileFormGroupMargin">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="tagline"
                onChange={(e)=>{
                  this.setState({
                    experience:{...this.state.experience,name:e.target.value}
                  })
                }}
                value={this.state.experience.name}
                />
                <Form.Text className="text-muted">Experience name.</Form.Text>
              </Form.Group>
              <Form.Group className="businessProfileFormGroupMargin">
                <Form.Label>Short Description</Form.Label>
                <Form.Control type="text" placeholder="tagline"
                onChange={(e)=>{
                  this.setState({
                    experience:{...this.state.experience,shortDescription:e.target.value}
                  })
                }}
                value={this.state.experience.shortDescription}
                />
                <Form.Text className="text-muted">Short description of the experience.</Form.Text>
              </Form.Group>
              <Form.Group className="businessProfileFormGroupMargin">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" placeholder="description"
                rows={20}
                ref={this.textAreaRef}
                onChange={(e)=>{
                  this.setState({
                    experience:{...this.state.experience,description:e.target.value}
                  })
                }}
                value={this.state.experience.description}
                />
                <Form.Text className="text-muted">Main description of the experience.</Form.Text>
              </Form.Group>
              <hr/>
              <div className="experiencePlacesContainer">
              Places in Experience:
              {this.state.experience.places.length == 0 ? (
                  <div className="pleaseAddExperience">please add a place</div>
              ) : (
                <>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                      {this.state.experience.places.map((item,index)=>(
                        <Draggable key={item._id} draggableId={item._id} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              className="experiencePlaceItem"
                            >
                              {item.name}
                              <div
                              style={{background:'red',padding:4}}
                              onClick={()=>{
                                this.removeExperienceItem(index);
                              }}
                              >remove</div>
                            </div>
                          )}
                        </Draggable>
                      )
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

                </>
              )}
              <AsyncSelect
                loadOptions={this.loadOptionsbounce}
                value={this.state.selectedValue}
                onChange={this.handleChange}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => e._id}
                placeholder={'Search places'}
                style={{
                  flex:1,
                  color:'red',
                  width:'100%'
                }}
              />
              <div>search</div>
              <button style={{marginTop:10}} type="submit" className="btn btn-danger btn-block">Add a Place</button>
              </div>
              <hr/>
              <Form.Group className="businessProfileFormGroupMargin">
                <Form.Label>Tags</Form.Label>
                <ReactTags tags={this.state.experience.tags}
                    suggestions={this.state.suggestions}
                    handleDelete={this._tagHandleDelete}
                    handleAddition={this._tagHandleAddition}
                    delimiters={delimiters}
                    allowDragDrop={false}
                     />
                <Form.Text>Add tags to have this experience show up in searches.</Form.Text>
              </Form.Group>
              <Form.Group className="md-3" controlId="stickerState">
                <Form.Label style={{
                  backgroundColor:this.state.experience.internalReviewStatus === "new" ? "red" : this.state.experience.internalReviewStatus === "needsreview" ? "yellow" : this.state.experience.internalReviewStatus === "completedGood" ? "green" : "blue",
                  color:this.state.experience.internalReviewStatus === "completedRejected" ? "white" : "black",
                  padding:6,
                  borderRadius:3
                }}>Review Status</Form.Label>
                <Form.Select
                  value={this.state.experience.internalReviewStatus}
                  onChange={(e)=>{
                    this.setState({
                      experience: {...this.state.experience,internalReviewStatus:e.target.value}
                    })
                  }}
                  >
                  <option value="new">new</option>
                  <option value="needsreview">needs review</option>
                  <option value="completedGood">completed - good</option>
                  <option value="completedRejected">completed - rejected</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="md-3" controlId="stickerState">
                <Form.Label style={{
                  backgroundColor:this.state.experience.internalReviewStatus === "new" ? "red" : this.state.experience.internalReviewStatus === "needsreview" ? "yellow" : this.state.experience.internalReviewStatus === "completedGood" ? "green" : "blue",
                  color:this.state.experience.internalReviewStatus === "completedRejected" ? "white" : "black",
                  padding:6,
                  borderRadius:3
                }}>Place Active Status</Form.Label>
                <Form.Select
                  value={this.state.experience.status}
                  onChange={(e)=>{
                    this.setState({
                      experience: {...this.state.experience,status:e.target.value}
                    })
                  }}
                  >
                  <option value="active">active</option>
                  <option value="disabled">disabled</option>
                  <option value="deleted">deleted</option>
                </Form.Select>
              </Form.Group>

              <Button variant="dougBlue" className="btn-danger" type="submit" style={{marginTop:20}}>submit</Button>
            </Form>
            <Button onClick={this.delete} variant="dougRed" className="btn-info" type="submit" style={{marginTop:20}}>delete {this.state.experience.name}</Button>
          </Col>
        </Row>
      </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  business:state.business,
  places:state.mapplaces
});

export default connect(mapStateToProps,{})(EditExperience)

const styles = {
  placearrows: {
    display:'flex',
    flexDirection:'row'
  },
  placearrow : {
    width:65,
    height:55,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    background:'#ebe0d0',
    margin:5,
    cursor:'pointer',
    borderRadius:5
  },
  placecounter : {
    fontSize:8,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    width:55,
    height:55
  }
}
