import React, { Component } from 'react';
import { connect } from "react-redux";
import API from '../utils/api';
import Loader from '../utils/loader';

export default class UserPoints extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            points:null,
            loading:true
        }
    }
    componentDidMount() {
        API.fetch({
            method:'GET',
            endpoint:'/super/points/userpoints/' + this.props.user
        }).then(res=>{
            console.log(res.data)
            this.setState({
                points:res.data,
                loading:false
            })
        }).catch(error=>{
            console.log(error)
        })
    }
    render() {
        console.log(this.state)
        console.log('the user ID = ',this.props.user)
        return (
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>Points: {this.state.loading ? <Loader/> : this.state.points?.totalPoints}</div>
        )
    }
}