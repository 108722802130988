import React, { Component } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { getItemStyle, getAnswerListStyle } from "./editRLutil";
import AsyncSelect from 'react-select/async';
import {mapboxToken,baseUrl} from '../config.js';
import fetchs3image from '../utils/fetchs3';

const Answers = props => {
  const { question, questionNum } = props;
  const [textInput, setTextInput] = React.useState('');
  const [selectedValue, setSelectedValue] = React.useState();
console.log('WHAT THE FUCK')
  const loadOptions = (inputValue) => {
    console.log('LOADING NEW STUFF **********')
    console.log('the input value',inputValue)
    //return fetch(`http://jsonplaceholder.typicode.com/posts?userId=${inputValue}`).then(res => res.json());
      return fetch(baseUrl + '/places/unpagedsearch',{
        method:'POST',
        headers : {
          Authorization: "Bearer " + props.access_token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({search:inputValue})
      }).then(res=>res.json())
      // return API.fetch({
      //   method:'POST',
      //   endpoint:'/places/unpagedsearch',
      //   data:{
      //     search:inputValue
      //   }
      // }).then(res=>res.json());
  };
  const handleChange = (value) => {
    props.addplace(value)
    //this.props.history.push('/place/'+value._id)
    setSelectedValue('')
    console.log('the selected value',value)
  }

  return (
    <Droppable droppableId={`droppable${question.name}`} type={`${questionNum}`}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getAnswerListStyle(snapshot.isDraggingOver)}
        >
        <div>
          <AsyncSelect
            loadOptions={loadOptions}
            value={selectedValue}
            onChange={handleChange}
            getOptionLabel={(e) => e.name}
            getOptionValue={(e) => e._id}
            placeholder={'Search places'}
            style={{
              flex:1,
              color:'red',
              width:'100%'
            }}
          />
        </div>
          {question.places.map((place, index) => {
            return (
              <Draggable
                key={`${questionNum}${index}`}
                draggableId={`${questionNum}${index}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <span {...provided.dragHandleProps}>
                    |||
                    </span>
                    {place.name}
                    <div style={{fontSize:9}}>{place.tagline}</div>
                    <div style={{width:55,height:55,borderRadius:25,
                    background:`url(${fetchs3image({ width:150,bucket:place.image?.s3image?.bucket,key:place.image?.s3image?.key})}) center / cover`,
                    }}
                    ></div>
                    <div style={{
                      background:'red',
                      width:25,
                      height:25,
                      color:'white',
                      display:'flex',
                      justifyContent:'center',
                      alignItems:'center',
                      borderRadius:10
                    }}
                    onClick={()=>{
                      props.removeplace(index)
                    }}
                    >X</div>
                  </div>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default Answers;
