import React, { Component } from 'react';
import { connect } from "react-redux";
import API from '../utils/api';
import ListGroup from 'react-bootstrap/ListGroup';
import Loader from '../utils/loader';
import AsyncSelect from 'react-select/async';
import _ from "lodash";
import { FaMapMarker } from "react-icons/fa";
import {
  setLocation,
  clearLocation
} from '../redux/actions/locationActions';

import {
  fetchListPlaces,
  fetchMoreListPlaces
} from '../redux/actions/placeActions';

class PlacesDisabled extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placesloading:true,
      page:0,
      loadingmore:false,
      searchVisible:false,
      searchtext:'',
      geoplaces:[],
      placees:[],
      filter:'all'
    }
  }
  componentDidMount() {
    this.setState({
      placesloading:true
    })
     API.fetch({
      method:'GET',
      endpoint:'/super/places/listinactive',
    }).then(res=> {
      console.log(res.data)
      this.setState({
        places:res.data,
        placesloading:false
      })
    }
    ).catch(error=>{
      console.log('error loading',error)
    })

  }

      //  API.fetch({
      //   method:'POST',
      //   endpoint:'/places/unpagedsearch',
      //   data:{
      //     search:inputValue
      //   }
      // }).then(res=>res.json());

  render() {
    console.log(this.props)
    return this.state.placesloading ? (
      <div className="container">
      loading
      </div>
    ) : (
      <div className="container">


        <div className="userList" style={{marginTop:15}}>
        <ListGroup>
          {this.state.places.map((place,index)=>{
            return (
              <>
              <ListGroup.Item key={place._id} className="userRow"
                style={{
                  cursor:'pointer',
                  display:'flex',
                  flexDirection:'row'
                }}

              >
              <button
              style={{marginRight:10}}
              onClick={()=>{
                API.fetch({
                  method:'post',
                  endpoint:'/super/places/superapprove',
                  data: {
                    placeId:place._id
                  }
                }).then(res=>{
                  console.log(res)
                  let newplace = res.data;
                  let placeindex = this.state.places.findIndex(e=>e._id == newplace._id);
                  console.log('the place indoex is!',placeindex)
                  let places = [...this.state.places];
                  places[placeindex] = newplace;
                  this.setState({places})
                })
              }}
              >super approve</button>
              <div style={{
                flex:1,
              }}
              onClick={()=>{
              this.props.history.push('/place/'+place._id);
            }}
              >
              <div>{index + 1} - {place.name}</div>
              <div style={{fontSize:12}}>{place.tagline}</div>
              <div style={{fontSize:12}}>{place.location?.name}</div>
              </div>
              <div style={{
                width:20,
                height:20,
                borderRadius:10,
                backgroundColor:place.internalReviewStatus === "new" ? "red" : place.internalReviewStatus === "needsreview" ? "yellow" : place.internalReviewStatus === "completedGood" ? "green" : "blue"
              }}></div>
              {place.status == 'disabled' &&
              <div>disabled</div>
            }
              </ListGroup.Item>
              </>
            )
          })}
          </ListGroup>

        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  geoLocation:state.location,
  places:state.mapplaces
});

export default connect(mapStateToProps,{setLocation,clearLocation,fetchListPlaces,fetchMoreListPlaces})(PlacesDisabled)
