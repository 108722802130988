import React, { Component } from 'react';
import { connect } from "react-redux";
import API from '../utils/api';
import ListGroup from 'react-bootstrap/ListGroup';
import {Table, Form, Button} from 'react-bootstrap';
import Loader from '../utils/loader';
import AsyncSelect from 'react-select/async';
import _ from "lodash";
import { FaMapMarker } from "react-icons/fa";
import {
  setLocation,
  clearLocation
} from '../redux/actions/locationActions';
import moment from 'moment';
import States from './States';

var requestTypes = [
  'businessClaim',
  'businessAdd',
  'stickerRequest'
];

var statuses = [
  'new',
  'working',
  'closed',
  'dead'
]
class FormView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formloading:true,
    }
  }
  componentDidMount() {
    this.setState({
      formsloading:true
    })
    API.forms({
      method:'GET',
      endpoint:'/list/forms/' + this.props.match.params.id,
    }).then(res=>{
      this.setState({
        form:res.data,
        formloading:false
      })
      console.log(res.data)
    }).catch(error=>{
      console.log(error)
      this.setState({
        formloading:false,
        error:true,
        form:null
      })
    })
  }
  update = (e) => {
    e.preventDefault();
    console.log(this.state)
    API.forms({
      method:'POST',
      endpoint:'/list/forms/update',
      data:this.state.form
    }).then(res=>{
	     alert('updated!');
      console.log(res.data)
    }).catch(error=>{
	     alert('error!');
      console.log('error',error)
    })
  }
  render() {
    console.log(this.state)
    return this.state.formloading ? (
      <div className="container">
      loading
      </div>
    ) : this.state.error ? (
      <div>error</div>
    ) :(
      <div className="container">
      <Form onSubmit={this.requeststickers}>
        <Form.Group className="mb-3" controlId="personName">
          <Form.Label>Contact</Form.Label>
          <Form.Control
          type="text"
          placeholder="contact name"
          value={this.state.form.personName}
          onChange={(e)=>{
            this.setState({
              form:{...this.state.form,personName:e.target.value}
            })
          }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="businessName">
          <Form.Label>Business Name</Form.Label>
          <Form.Control
          type="text"
          placeholder="business name"
          value={this.state.form.businessName}
          onChange={(e)=>{
            this.setState({
              form:{...this.state.form,businessName:e.target.value}
            })
          }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
          type="text"
          placeholder="email"
          value={this.state.form.email}
          onChange={(e)=>{
            this.setState({
              form:{...this.state.form,email:e.target.value}
            })
          }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="phone">
          <Form.Label>phone</Form.Label>
          <Form.Control
          type="phone"
          placeholder="phone"
          value={this.state.form.phone}
          onChange={(e)=>{
            this.setState({
              form:{...this.state.form,phone:e.target.value}
            })
          }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="streetAddress">
          <Form.Label>Address</Form.Label>
          <Form.Control
          type="text"
          placeholder="street address"
          value={this.state.form.streetAddress}
          onChange={(e)=>{
            this.setState({
              form:{...this.state.form,streetAddress:e.target.value}
            })
          }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="city">
          <Form.Label>City</Form.Label>
          <Form.Control
          type="text"
          placeholder="city"
          value={this.state.form.city}
          onChange={(e)=>{
            this.setState({
              form:{...this.state.form,city:e.target.value}
            })
          }}
          />
        </Form.Group>
        <Form.Group className="md-3" controlId="state">
          <Form.Label>State</Form.Label>
          <Form.Select
          value={this.state.form.state}
          onChange={(e)=>{
            this.setState({
              form:{...this.state.form,state:e.target.value}
            })
          }}
          required
          >
          <States/>
          </Form.Select>
        </Form.Group>
        <Form.Group controlId="requestType">
          <Form.Label>Request Type</Form.Label>
          <Form.Select
          value={this.state.form.requestType}
          onChange={(e)=>{
            this.setState({
              form:{...this.state.form,requestType:e.target.value}
            })
          }}
            >
            {requestTypes.map(req=>{
              return (<option key={req} value={req}>{req}</option>)
            })}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="message">
          <Form.Label>Message</Form.Label>
          <Form.Control
          as="textarea"
          rows={3}
          placeholder="message"
          value={this.state.form.message}
          onChange={(e)=>{
            this.setState({
              form:{...this.state.form,message:e.target.value}
            })
          }}
          />
        </Form.Group>
        <Form.Group controlId="status">
          <Form.Label>Status</Form.Label>
          <Form.Select
          value={this.state.form.status}
          onChange={(e)=>{
            this.setState({
              form:{...this.state.form,status:e.target.value}
            })
          }}
            >
            {statuses.map(req=>{
              return (<option key={req} value={req}>{req}</option>)
            })}
          </Form.Select>
        </Form.Group>
        <Button variant="info" style={{color:'white',marginTop:20}} onClick={this.update}>Update</Button>
      </Form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  geoLocation:state.location,
});

export default connect(mapStateToProps,{setLocation,clearLocation})(FormView)
