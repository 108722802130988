import React, { Component } from 'react';
import { connect } from "react-redux";
import API from '../utils/api';
import ListGroup from 'react-bootstrap/ListGroup';
import _ from "lodash";
import AsyncSelect from 'react-select/async';
import PlaceFromId from './PlaceFromId';
import {Button} from 'react-bootstrap';
import moment from 'moment';
import fetchs3image from '../utils/fetchs3';
import UserPoints from './UserPoints';


class UserView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true
    }
    this.loadOptionsbounce = _.debounce(this.loadOptions, 350);
  }

  loadOptions = (inputValue) => {
    console.log('LOADING NEW STUFF **********')
    console.log('the input value',inputValue)
    //return fetch(`http://jsonplaceholder.typicode.com/posts?userId=${inputValue}`).then(res => res.json());
      return fetch('https://api.douglovesyou.com/places/unpagedsearch',{
        method:'POST',
        headers : {
          Authorization: "Bearer " + this.props.auth.user.signInUserSession.accessToken.jwtToken,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({search:inputValue})
      }).then(res=>res.json());
  };
  handleChange = (value) => {
    console.log(value._id)
    console.log(this.state.user)
    this.setState({
      user:{...this.state.user,businessProfiles:[...this.state.user.businessProfiles,value._id]}
    })
  }
  componentDidMount() {
    this.setState({
      loading:true
    })
    API.fetch({
      method:'POST',
      endpoint:'/admin/users/admin/list/' + this.props.match.params.id
    }).then(res=>{
      console.log(res.data)
      this.setState({
        user:res.data,
        loading:false
      })
    }).catch(error=>{
      console.log(error)
      this.setState({
        error:true
      })
    })
  }
  _remove_from_list = (place) => {
    console.log('place id',place);

    var array = [...this.state.user.businessProfiles];
    var index = array.indexOf(place);
    array.splice(index,1);
    this.setState({
      user:{...this.state.user,businessProfiles:array}
    })
  }

  updateUser() {
    this.setState({
      loading:true
    })
    const requestBody = this.state.user;
    console.log('the request body')
    console.log(requestBody);
    API.fetch({
      method:'POST',
      endpoint:'/admin/users/update/' + this.props.match.params.id,
      data:requestBody
    })
    .then(result=>{
      console.log('ere whay we got back',result.data)
      this.setState({
        loading:false,
        user:result.data
      })
      console.log(result)
    })
    .catch(error=>{
      console.log(error)
    })
  }
  render() {
    console.log(this.state.user);
    return this.state.loading ? (
      <div className="container">
      loading
      </div>
    ) : (
      <div className="container">
        <div className="userCard">
          <div className="userCardTop">
            <img src={fetchs3image({...this.state.user.profileImage,width:200})} style={{width:150,height:150}}/>
            <div className="userName">{this.state.user.userName}</div>
            <div style={{fontSize:10}}>{this.state.user.email}</div>
            <div style={{fontSize:10}}>{this.state.user.homeLocation?.name}</div>
            <div style={{fontSize:10}}>Joined {moment(this.state.user.createdAt).fromNow()}</div>
            <UserPoints user={this.props.match.params.id}/>
          </div>
          <div className="userPermissions">
            <div style={{display:'flex',flexDirection:'row'}}>
              <div style={{width:200}}>Mobile user:</div><div>
                <input type="checkbox" checked={this.state.user.roles.includes("mobileUser")}
                onChange={(e)=>{
                  if(this.state.user.roles.includes('mobileUser')) {
                    this.setState(prevState => {
                      return {
                        user: {
                          ...this.state.user, roles:prevState.user.roles.filter(i=>i !== 'mobileUser')
                        }

                      }
                    })
                    console.log('remove')
                  } else {
                    this.setState({
                      user : {
                        ...this.state.user, roles:[...this.state.user.roles, 'mobileUser']
                      }
                    })
                    console.log('add')
                  }

                }
                }
                />
              </div>
            </div>
            <div style={{display:'flex',flexDirection:'row'}}>
              <div style={{width:200}}>Business owner:</div><div>
                <input type="checkbox" checked={this.state.user.roles.includes("businessOwner")}
                onChange={(e)=>{
                  if(this.state.user.roles.includes('businessOwner')) {
                    this.setState(prevState => {
                      return {
                        user: {
                          ...this.state.user, roles:prevState.user.roles.filter(i=>i !== 'businessOwner')
                        }

                      }
                    })
                    console.log('remove')
                  } else {
                    this.setState({
                      user : {
                        ...this.state.user, roles:[...this.state.user.roles, 'businessOwner']
                      }
                    })
                    console.log('add')
                  }

                }
                }
                />
              </div>
            </div>
            <div style={{display:'flex',flexDirection:'row'}}>
              <div style={{width:200}}>Admin:</div><div>
                <input type="checkbox" checked={this.state.user.roles.includes("admin")}
                onChange={(e)=>{
                  if(this.state.user.roles.includes('admin')) {
                    this.setState(prevState => {
                      return {
                        user: {
                          ...this.state.user, roles:prevState.user.roles.filter(i=>i !== 'admin')
                        }

                      }
                    })
                    console.log('remove')
                  } else {
                    this.setState({
                      user : {
                        ...this.state.user, roles:[...this.state.user.roles, 'admin']
                      }
                    })
                    console.log('add')
                  }

                }
                }
                />
              </div>
            </div>
          </div>
          <div className="userBusinesses">
          Businesses:
          {this.state.user.businessProfiles.map(business=>{
            return (
              <div key={business} style={{marginBottom:5}}><PlaceFromId placeId={business} remove={this._remove_from_list}/></div>
            )
          })}
          </div>
          <AsyncSelect
            loadOptions={this.loadOptionsbounce}
            value={this.state.selectedValue}
            onChange={this.handleChange}
            getOptionLabel={(e) => e.name}
            getOptionValue={(e) => e._id}
          />
          <Button onClick={()=>{this.updateUser()}}>Update user</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  geoLocation:state.location
});

export default connect(mapStateToProps,{})(UserView)
