import React, { Component } from 'react';
import { connect } from "react-redux";
import API from '../utils/api';
import ListGroup from 'react-bootstrap/ListGroup';
import Loader from '../utils/loader';
import AsyncSelect from 'react-select/async';
import _ from "lodash";
import { FaMapMarker } from "react-icons/fa";
import {
  setLocation,
  clearLocation
} from '../redux/actions/locationActions';
import {baseUrl} from '../config.js';
import heart from './heart.png';
import {
  fetchListPlaces,
  fetchMoreListPlaces,
  fetchAllPlacesAlpha
  
} from '../redux/actions/placeActions';

import ReactMapboxGl, { Layer, Feature, Marker, Cluster, Popup } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {mapboxToken} from '../config.js';

const latitude = 38.527951;
const longitude = -95.169377;
const Map = ReactMapboxGl({
    accessToken:
      mapboxToken
  });
  
  const zoom = [3];
  const center = [
    longitude,
    latitude
  ]
  

class BigMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placesloading:true,
      page:0,
      loadingmore:false,
      searchVisible:false,
      searchtext:'',
      geoplaces:[],
      filter:'all'
    }
  }
  componentDidMount() {
    this.setState({
      placesloading:true
    })
    this.props.fetchAllPlacesAlpha({
      location:this.props.geoLocation.active ? this.props.geoLocation.coordinates : null,
      filter:this.state.filter
    })
  }
  onMarkerClick(e,other) {
    this.setState({
      popupLatitude:e.location.coordinates[1],
      popupLongitude:e.location.coordinates[0],
      popupName:e.name,
      popupTagline:e.tagline,
      popupImage:e.image.s3image,
      popupID:e._id,
      showPopup:true
    })

  }
  onMapClick(map,ev) {
  }
  clusterMarker = (coordinates,pointCount) => (
    <Marker coordinates={coordinates} style={styles.clusterMarker}>
      <div style={styles.clusterContainer}>
      <img src={heart} style={styles.bigHeart}/>
      <div style={styles.pointCount}>{pointCount}</div>
      </div>
    </Marker>
  );
  filterRefresh(filter) {
    this.setState({
      placesloading:true,
      page:0,
      searchText:''
    })
    this.props.fetchListPlaces({
      location:this.props.geoLocation.active ? this.props.geoLocation.coordinates : null,
      filter:filter
    })

  }


  loadOptions = (inputValue) => {
    console.log('LOADING NEW STUFF **********')
    console.log('the input value',inputValue)
    //return fetch(`http://jsonplaceholder.typicode.com/posts?userId=${inputValue}`).then(res => res.json());
      return fetch(baseUrl + '/places/unpagedsearch',{
        method:'POST',
        headers : {
          Authorization: "Bearer " + this.props.auth.access_token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({search:inputValue})
      }).then(res=>res.json())
      // return API.fetch({
      //   method:'POST',
      //   endpoint:'/places/unpagedsearch',
      //   data:{
      //     search:inputValue
      //   }
      // }).then(res=>res.json());
  };

  handleChange = (value) => {
    this.setState({
      selectedValue : value
    })
    this.props.history.push('/place/'+value._id)
  }
  placeSearch = (text) => {
    API.fetch({
      method:'POST',
      endpoint:'/geo/searchforlocation',
      data: {
        search:text
      }
    })
    .then(result=>{
      console.log('search regult is ',result)
      this.setState({
        geoplaces:result.data.features
      })
    })
    .catch(error=>{
    })
  }
  reload = (coords) => {
    this.setState({
      placesloading:true,
      page:0,
      loadingmore:false,
      searchVisible:false,
      searchtext:'',
      geoplaces:[]

    })
    this.props.fetchListPlaces({
      location:coords,
      page:0
    })
  }
  render() {
    console.log(this.props)
    return this.props.places.listplacesloading ? (
      <div className="container">
      loading
      </div>
    ) : (
      <div className="container">BIG MAP
        <div className="selectWrapPlaces"
          style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center'
          }}
        >
        <div style={{
          flex:1
        }}>
        <AsyncSelect
          loadOptions={this.loadOptions}
          value={this.state.selectedValue}
          onChange={this.handleChange}
          getOptionLabel={(e) => e.name}
          getOptionValue={(e) => e._id}
          placeholder={'Search places'}
          style={{
            flex:1,
            color:'red',
            width:'100%'
          }}
        />
        </div>
        <div style={{
          paddingLeft:15,
          paddingRight:15,
          position:'relative'
        }}
        >
        {this.props.geoLocation.active ? (
          <div
          onClick={()=>{
            this.setState({
                searchVisible:!this.state.searchVisible
            })
          }}
          style={{cursor:'pointer'}}
          >
          Searching in {this.props.geoLocation.name}<FaMapMarker style={{marginLeft:3}}/>
          </div>


      ) : (
        <div
        onClick={()=>{
          this.setState({
              searchVisible:!this.state.searchVisible
          })
        }}
        style={{cursor:'pointer'}}
        >
        Set location <FaMapMarker style={{marginLeft:3}}/>
        </div>


      )}

        {this.state.searchVisible &&
          <div
            style={{
              position:'absolute',
              top:30,
              right:10,
              width:250,
              border:'1px solid #dedede',
              borderRadius:5,
              zIndex:999,
              padding:6
            }}
          >
            <input
            style={{
              width:'100%'
            }}
            placeholder="type to teleport"
            value={this.state.searchtext}
            onChange={(e)=>{
              this.setState({
                searchtext:e.target.value
              })
              this.text = e.target.value;
              clearTimeout(this.timeout);
              this.timeout = setTimeout(()=>this.placeSearch(this.text),500);
            }}
            />
            {this.state.geoplaces.length > 0 &&
              <div>
              {this.state.geoplaces.map(place=>{
                console.log('THE PLACE IS',place)
                return (
                  <div key={place.id}
                    style={{
                      background:'#efefef',
                      padding:4,
                      marginTop:4,
                      fontSize:11,
                      borderRadius:3,
                      cursor:'pointer'
                    }}
                    onClick={()=>{
                      console.log(place.geometry.coordinates)
                      this.props.setLocation({name:place.place_name,coords:place.geometry.coordinates})
                      this.setState({
                        geoplaces:[],
                        searchVisible:false,
                        searchtext:''
                      })
                      this.props.fetchListPlaces({
                        location:place.geometry.coordinates,
                        filter:this.state.filter
                      })

                    }}
                  >{place.place_name}</div>
                )
              })}
              </div>
            }
            {this.props.geoLocation.active &&
            <div
            style={{
              background:'#efefef',
              padding:4,
              marginTop:4,
              fontSize:11,
              borderRadius:3,
              cursor:'pointer'
            }}
            onClick={()=>{
              this.props.clearLocation()
              this.setState({
                geoplaces:[],
                searchVisible:false,
                searchtext:''
              })
              this.props.fetchListPlaces({
                location:null,
                filter:this.state.filter
              })


            }}
            >Clear Location</div>
          }
        </div>
        }
      </div>
      </div>
      <div className="filterTool">
      <select
        value={this.state.filter}
        onChange={(e)=>{
          this.setState({
            filter:e.target.value
          })
          this.filterRefresh(e.target.value)
        }}
        style={{
          padding:8,
          marginTop:5
        }}
      >
          <option value="all">all</option>
          <option value="new">new</option>
          <option value="needsreview">needs review</option>
          <option value="completedGood">completed - good</option>
          <option value="completedRejected">completed - rejected</option>
      </select>
      </div>
      <Map
          style="mapbox://styles/mapbox/streets-v9"
          containerStyle={{
            height: 'calc(100vh - 110px)',
            width: '100%'
          }}
          zoom={zoom}
          center={center}
          onClick={(e)=>{
            this.setState({
              showPopup:false
            })
          }}
        >
        {this.state.loading ? (
          null
        ) : (
          <>
          <Cluster ClusterMarkerFactory={this.clusterMarker}>
          {this.props.places.listplaces.map((place,key)=>{
            return (
              <Marker
                key={key}
                coordinates={[place.location.coordinates[0], place.location.coordinates[1]]}
                anchor="bottom"
                onClick={this.onMarkerClick.bind(this, place)}>
                <img src={heart} style={styles.heart}/>
              </Marker>

            )

          })}
          </Cluster>
          </>
        )}


        <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
          <Feature coordinates={[longitude, latitude]} />
        </Layer>
        {this.state.showPopup &&
          <Popup
            coordinates={[this.state.popupLongitude,this.state.popupLatitude]}
            offset={{
              'bottom-left': [12, -38],  'bottom': [0, -38], 'bottom-right': [-12, -38]
            }}>
            <div style={styles.popupWrap}>
            <div style={styles.popupName}>{this.state.popupName}</div>
            <div style={styles.popupTagline}>{this.state.popupTagline}</div>
            <div style={styles.popupImage}>
              {/* {this.state.popupImage ? (
                <ImageLoad src={this.state.popupImage}/>
              ) : (null)
            } */}
            </div>
            <div style={styles.editplace} onClick={()=>this.props.history.push('/place/'+ this.state.popupID)}>edit</div>

            </div>
          </Popup>
        }

        </Map>
        <div className="userList" style={{marginTop:15}}>

          {this.props.places.listplacesfetchingmore ? (
              <Loader/>
          ) : this.props.places.listplaces.length > 0 ? (

            <button onClick={
              ()=>{
                this.props.fetchMoreListPlaces({
                  location:this.props.geoLocation.active ? this.props.geoLocation.coordinates : null,
                  filter:this.state.filter
                })
              }
            }
            style={{
              marginTop:10,
              marginBottom:75
            }}
            >load more</button>
          ) : null}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  geoLocation:state.location,
  places:state.mapplaces
});

export default connect(mapStateToProps,{setLocation,clearLocation,fetchListPlaces,fetchMoreListPlaces,fetchAllPlacesAlpha})(BigMap)


const styles = {
    heart : {
      width:20,
      height:20
    },
    bigHeart : {
      width:40,
      height:40
    },
    pointCount : {
      position:'absolute',
      top:0,
      left:0,
      bottom:0,
      right:0,
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      color:'white'
    },
    popupWrap : {
      width:200
    },
    popupName: {
      fontSize:15,
      marginBottom:2,
      lineHeight:1
    },
    popupTagline: {
      fontSize:10,
      lineHeight:1.2,
      marginBottom:5
    },
    popupImage: {
      width:190,
  
    },
    editplace : {
      color:'red',
      fontSize:9,
      cursor:'pointer'
    }
  }

  
